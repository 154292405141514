import React, { useState, useEffect, useContext } from 'react';
import { View, useWindowDimensions } from 'react-native'
import { MaterialIcons } from '@expo/vector-icons'; 
import styled, { useTheme } from 'styled-components/native';
import Html from '../Commons/Html';
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import Choices from './Choices';
import FillIn from './FillIn';
import WhiteButton from '../Commons/WhiteButton';
import GhostButton from '../Commons/GhostButton';

import { ExamContext } from '../../contexts/ExamContext'

const BOTTOM_BAR_HEIGHT = 80

const getQustionMaxWidth = (screenSize) => {
  if (screenSize.sm) return 300
  if (screenSize.md) return 360
  if (screenSize.lg) return 380
  if (screenSize.xl) return 600
  return 720
}

const QuestionTitle = styled.Text`
  font-size: 14px;
  font-family: ${Fonts.brandBold};
  color: ${Colors.slate500};
  margin-bottom: 15px;
  text-align: left;
  padding-top: 5px;
  width: ${({theme}) => getQustionMaxWidth(theme.screenSize)}px;
`

const QuestionView = styled.View`
  flex: ${({theme}) => theme.screenSize.md ? 'none' : 3 };
  /* flex: ${({zoomChoices}) => zoomChoices ? 1 : 2}; */
  padding-bottom: ${({theme}) => theme.screenSize.md ? 0 : `${BOTTOM_BAR_HEIGHT}px` };
  border-right-width: 2px;
  border-right-color: ${Colors.slate300};
  display: ${({zoomChoices}) => zoomChoices ? "none" : "flex"};
  height: auto;
`
const ChoiceView = styled.View`
  flex: ${({theme}) => theme.screenSize.md ? 'none' : 2 };
  /* flex: ${({zoomChoices}) => zoomChoices ? 2 : 1}; */
  padding-bottom: ${({theme}) => theme.screenSize.md ? `20px` : `${BOTTOM_BAR_HEIGHT}px` };
  height: auto;
`
const FillInView = styled.View`
  flex: ${({theme}) => theme.screenSize.md ? 'none' : 2 };
  background: #fff;
  padding: ${({theme}) => theme.screenSize.md ? '20px 0' : `80px 0 0` };
  height: auto;
`
const QuestionContainer = styled.View`
  margin-bottom: ${({theme}) => theme.screenSize.md ? 0 : '120px' };
  justify-content: center;
  align-items: center;
`
const QuestionScrollViewContainerDesktop = styled.ScrollView`
  background: #fff;
  padding: 80px 20px 0;
  flex: 1;
`
const QuestionViewContainerMobile = styled.ScrollView`
  background: #fff;
  padding: 80px 20px 0;
`

const ChoicesScrollViewContainerDesktop = styled.ScrollView`
  background: #fff;
  padding: 80px 20px 0;
  flex: 1;
`
const ChoicesViewContainerMobile = styled.ScrollView`
  background: #fff;
  padding: 20px 20px 0;
`

const QuestionAction = styled.View`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: ${({theme}) => theme.screenSize.md ? '26px' : 0 };
  height: ${BOTTOM_BAR_HEIGHT}px;
  z-index: 20;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const ZoomButton = styled(GhostButton)`
  flex-shrink: 1;
  position: absolute;
  left: 20px;
  top: 25px;
  height: 30px;
  z-index: 10;
  background: #fff;
  display: ${({theme}) => theme.screenSize.md ? 'none' : 'flex' };
`

function Question({question, currentQuestionIndex, handleNextQuesiton, handlePrevQuestion}) {

  const { answers, doAnswer, answerLoading } = useContext(ExamContext)
  const theme = useTheme()

  const [zoomChoices, setZoomChoices] = useState(false)
  const [optionIdSelected, setOptionIdSelected] = useState(null)
  const [fillValue, setFillValue] = useState("")
  const [readyAnswer, setReadyAnswer] = useState(false)
  const windowDimensions = useWindowDimensions()
  const windowWidth = windowDimensions.width;
  
  const handleSelect = (optionId) => {
    doAnswer(optionId, currentQuestionIndex, question?.type)
    setOptionIdSelected(optionId)
    setReadyAnswer(true)
  }

  const handleOnChangeFill = (value) => {
    doAnswer(value, currentQuestionIndex, question?.type)
    setFillValue(value)
    if (value === "") {
      setReadyAnswer(false)
    } else {
      setReadyAnswer(true)
    }
  }

  useEffect(() => {
    if (answers && answers[currentQuestionIndex]) {
      setReadyAnswer(true)
      if (question?.type == "choice") {
        setOptionIdSelected(answers[currentQuestionIndex].answer)
      } else {
        setFillValue(answers[currentQuestionIndex].answer)
      }
    } else {
      setOptionIdSelected(null)
      setFillValue("")
      setReadyAnswer(false)
    }

    setZoomChoices(false)
  }, [question])

  const handleZoomChoices = (isZoom) => {
    setZoomChoices(isZoom)
  }

  const QuestionWrapper = theme.screenSize.md ? QuestionViewContainerMobile : QuestionScrollViewContainerDesktop
  const ChoiceWrapper = theme.screenSize.md ? ChoicesViewContainerMobile : ChoicesScrollViewContainerDesktop
  
  return (
    <>
      <QuestionAction>
        <WhiteButton title={`ก่อนหน้า`} disabled={currentQuestionIndex <= 0 || answerLoading} onPress={handlePrevQuestion} />
        <View style={{width: 20}} />
        <WhiteButton title={`ถัดไป`} disabled={!readyAnswer || answerLoading} onPress={handleNextQuesiton} loading={answerLoading} />
      </QuestionAction>
      <QuestionView zoomChoices={zoomChoices}>
        <QuestionWrapper contentContainerStyle={{ flexGrow: 1 }}>
          <QuestionContainer>
            <QuestionTitle>คำถามข้อที่ {currentQuestionIndex + 1}</QuestionTitle>
            <Html 
              data={question?.text}
              width={getQustionMaxWidth(theme.screenSize)}
              baseFontStyle={{
                fontSize: 16,
                lineHeight: 1.8 * 16,
              }}
              disableImageClickable={true}
            />
          </QuestionContainer>
        </QuestionWrapper>
      </QuestionView>
      { question?.type == "fill_int" || question?.type == "fill_float"
      ? (<FillInView>
        <FillIn fillValue={fillValue} handleOnChangeFill={handleOnChangeFill} />
      </FillInView>)
      : (<ChoiceView zoomChoices={zoomChoices}>
          <ZoomButton 
            onPress={() => { handleZoomChoices( !zoomChoices ) }}
            title={`${ zoomChoices ? "ย่อ" : "ขยาย"}` }
            leftElement={()=> <MaterialIcons name={`${ zoomChoices ? "zoom-out-map" : "zoom-out-map"}`} size={16} color={Colors.slate600} style={{marginRight: 5}} />} 
            titleStyle={{fontSize: 13}}
          />
          <ChoiceWrapper>
            <Choices width={ zoomChoices ? (100 / 100) * windowWidth : (33.33 / 100) * windowWidth } options={question?.options} optionIdSelected={optionIdSelected} handleSelect={handleSelect} />
          </ChoiceWrapper>
        </ChoiceView>)
      }
      
    </>
  );
}

export default Question;