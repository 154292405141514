export const maskNationalId = (nationalId) => {
  if (!nationalId) return nationalId
  const lastDigit = nationalId.substr(nationalId.length - 3)
  return `X-XXXX-XXXX-${lastDigit}`
}

export const normalizeAbility = (ability = null) => {
  if (ability == null || typeof ability == 'undefined') {
    return null
  }
  // ability -5-5 to 0-1
  return ((ability + 5) / 10)
}

export const normalizeAbilityToPercent = (ability = null) => {
  if (ability == null || typeof ability == 'undefined') {
    return null
  }
  // ability -5-5 to 0-100
  return (((ability + 5) / 10) * 100).toFixed(2)
}

export const bytesToSize = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return 'n/a'
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
  if (i === 0) return `${bytes} ${sizes[i]}`
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
}