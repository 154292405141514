import React, { useState } from 'react';
import { Platform } from 'react-native'
import styled from 'styled-components/native';
import Colors from '../../themes/Colors';
import { Picker } from '@react-native-picker/picker';

const PickerContainer = styled.View`
  border: 1px solid ${Colors.slate300};
  border-radius: 6px;
  height: ${ Platform.OS == 'web' ? 40 : 45}px;
  padding: 0 10px;
`

function FormSelect({name, placeholder, items , value: defaultValue, onChange, disabled, onBlur, ...props}) {
  const [value, setValue] = useState(defaultValue)
  return (
    <PickerContainer>
      <Picker
        mode='dropdown'
        style={{width: "100%", height: "100%", borderWidth: 0, marginTop: Platform.OS == 'android' ? -6 : 0}}
        enabled={!disabled}
        selectedValue={value}
        onBlur={onBlur}
        onValueChange={(itemValue, index) => {
          setValue(itemValue)
          onChange(itemValue, name, items[index-1] )
        }}>
          <Picker.Item key={`placeholder-${name}`} style={{
            color: Colors.slate300
          }} label={`-- ${placeholder} --`} value={""} />
          { items.map(item => <Picker.Item style={{
            height: 30,
            color: '#000000',
          }} key={`${item.value}`} label={`${item.label}`} value={item.value} />) }
          { props.appendItems && props.appendItems.map(item => <Picker.Item style={{
            height: 30,
            color: '#000000',
          }} key={`${item.value}`} label={`${item.label}`} value={item.value} />) }
      </Picker>
    </PickerContainer>
  )
}

export default FormSelect;