import React from 'react';
import { useWindowDimensions } from 'react-native'
import CheckBox from "expo-checkbox";
import styled, { useTheme } from 'styled-components/native';
import BrandButton from '../Commons/BrandButton'
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';

const CoverLabel = styled.Text`
  color: ${Colors.brand2};
  font-family: ${Fonts.brandBold};
  font-size: 18px;
  flex-shrink: 1;
  margin-bottom: 10px;
`
const CoverTitle = styled.Text`
  background: ${Colors.brand};
  color: ${Colors.white};
  font-family: ${Fonts.brand2};
  font-size: 44px;
  flex-shrink: 1;
  padding: 0 10px;
  margin-bottom: 20px;
`
const CoverDetail = styled.Text`
  color: ${Colors.slate700};
  font-family: ${Fonts.brand};
  font-size: 18px;
  flex-shrink: 1;
  margin-bottom: 10px;
  max-width: 500px;
  text-align: center;
`
const CoverAction = styled.View`
  align-items: center;
  margin-top: 20px;
`

function LocusOfControlCover({chapter, handleStartChapter}) {
  const theme = useTheme()
  const windowDimensions = useWindowDimensions()

  const LocusOfControlCoverDesktop = styled.View`
    background: ${Colors.white};
    align-items: center;
    justify-content: center;
    flex: 1;
  `
  const LocusOfControlCoverMobile = styled.View`
    align-items: center;
    justify-content: center;
    display: flex;
    background: #fff;
    height: ${windowDimensions.height}px;
    padding: 20px;
    text-align: center;
  `

const CoverWrapper = theme.screenSize.md ? LocusOfControlCoverMobile : LocusOfControlCoverDesktop

  return (
    <CoverWrapper>
      <CoverTitle>{chapter&& chapter.label}</CoverTitle>
      <CoverLabel>แบบประเมินความเชื่ออำนาจ (Locus of Control)</CoverLabel>
      <CoverDetail>
        กรุณาเลือกคำตอบในช่อง <CheckBox /> ที่มีข้อความตรงกับความเป็นจริงของนักเรียนมากที่สุด 
        และขอความร่วมมือตอบคำถามให้ครบทั้ง 21 ข้อ
      </CoverDetail>
      <CoverAction>
        <BrandButton title="เริ่มทำแบบประเมิน" onPress={handleStartChapter} />
      </CoverAction>
    </CoverWrapper>
  );
}

export default LocusOfControlCover;