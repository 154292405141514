import React, { useState, useEffect } from 'react';
import FormSelect from '../Commons/FormSelect'
import useAPI from '../../hooks/useAPI';

function SelectList({type, ...props}) {
  const { api } = useAPI()
  const [lists, setLists] = useState([])
  useEffect(()=>{
    fetchAllLists(type, 1, [])
  }, [])

  const fetchAllLists = async (type, page, prevData) => {
    const pageSize = 100
    const res = await api.get('/lists', {
      sort: "ivalue",
      type,
      limit: pageSize,
      skip: (page-1) * pageSize,
    })
    if (res?.data) {
      const allData = [
        ...prevData,
        ...res?.data?.data
      ]
      if (res?.data?.size >= pageSize) {
        fetchAllLists(client_id, page+1, allData)
      } else {
        setLists(allData)
      }
    }
  }

  return (
    <FormSelect 
      items={lists.map((item) => {
        return {
          value: item.id,
          label: item.name,
        }
      })}
      {...props}
    />
  );
}

export default SelectList;