import axios from 'axios'
import AsyncStorage from "@react-native-async-storage/async-storage";
import { API_URL } from '@env'

console.log('API_URL', API_URL)

const API_TIMEOUT = 6000

const baseURL = API_URL
const API = axios.create({ baseURL, timeout: API_TIMEOUT })

let refreshing = false
let subscribers = []

const setToken = async (tokenResponse) => {
  try {
    await AsyncStorage.setItem("access_token", tokenResponse?.access_token);
    await AsyncStorage.setItem("refresh_token", tokenResponse?.refresh_token);
  } catch (error) {
    console.warn(error)
  }
}

const refreshToken = async () => {
  try {
    const refresh_token = await AsyncStorage.getItem("refresh_token");
    if (!refresh_token) return null
    const res = await axios.put( `${baseURL}/renew`, {
      refresh_token
    })
    if (res?.data) {
      await AsyncStorage.setItem("access_token", res?.data?.access_token);
      await AsyncStorage.setItem("refresh_token", res?.data?.refresh_token);
      if (res?.data?.access_token) {
        return Promise.resolve(res.data.access_token)
      }
    }
  } catch (error) {
    await AsyncStorage.removeItem("access_token");
    await AsyncStorage.removeItem("refresh_token");
    error.forceLogout = true
    return Promise.reject(error)
  }
}

API.interceptors.request.use(
  async (config) => {
    config.headers['Accept'] = 'application/json'
    if (!config.headers['Content-Type']) {
      config.headers['Content-Type'] = 'application/json'
    }
    if (!(config.url.startsWith('/login'))) {
      try {
        const token = await AsyncStorage.getItem("access_token");
        if (token) {
          config.token = token
          config.headers['Authorization'] = `Bearer ${token}`
        }
      } catch (error) {
        console.warn(error)
      }
    }
    return Promise.resolve(config)
    return config
  },
  error => Promise.reject(error)
)

API.interceptors.response.use(
  response => {
    const { config } = response
    if (config.url.includes('/login')) {
      setToken(response.data)
    }
    return response;
  }, 
  async (error) => {
    if (error?.response?.status == 401 && error?.response?.data?.message == 'Expired token') {
      try {
        const retry = new Promise((resolve, reject) => {
          subscribe(token => {
            if (token) {
              error.config.headers['Authorization'] = 'Bearer ' + token;
              resolve(API.request(error.config))
            } else {
              console.warn('token is undefined!')
              reject(error)
            }
          })
        })

        if (refreshing == false) {
          refreshing = true
          try {
            const refresh_token = await AsyncStorage.getItem("refresh_token");
            if (!refresh_token) {
              return Promise.reject(error);
            }
            const token = await refreshToken()
            publish(token)
          } catch (error) {
            publish()
          }
          refreshing = false
          unsubscribeAll()
        }

        return retry
      } catch (error) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  });

const publish = token => subscribers.map(cb => cb(token))
const subscribe = cb => subscribers.push(cb)
const unsubscribeAll = () => { subscribers = [] }


export default API