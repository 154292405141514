import React, { useContext } from 'react';
import { Text, View, Button, ScrollView } from 'react-native';
import styled from 'styled-components/native'
import Images from '../themes/Images'
import Colors from '../themes/Colors'
import Fonts from '../themes/Fonts'

import AccountPanel from '../components/Account/AccountPanel';

function CoverExamScreen({route, navigation}) {

  const handleBackHome = () => {
    navigation.navigate("Home", {
      reset: true,
    })
  }

  return (
    <CoverExamWrapper>
      <CoverExamBanner source={Images.mainBg} resizeMode="cover" >
        <CoverExamContentWrapper source={Images.bgCard} resizeMode="cover" imageStyle={{ borderRadius: 20}}>
          <CoverExamSubTitle>ข้อสอบวัดความสามารถทางคณิตศาสตร์</CoverExamSubTitle>
          <CoverExamTitle>ข้อสอบมี 4 ตอน</CoverExamTitle>
          <CoverExamDetail>
            - ตอนที่ 1-3 ตอนละ 8-10 ข้อ
          </CoverExamDetail>
          <CoverExamDetail>
            - ตอนที่ 4 จะเป็นข้อสอบความคิดเห็น 21 ข้อ
          </CoverExamDetail>
        </CoverExamContentWrapper>
      </CoverExamBanner>
      <AccountWrapper>
        <AccountPanel handleBack={handleBackHome}/>
      </AccountWrapper>
    </CoverExamWrapper>
  );
}

//Mitr_300Light | Mitr_500Medium | Chonburi_400Regular
const CoverExamWrapper = styled.View`
  flex: 1;
  justify-content: center;
  flex-direction: ${({theme}) => theme.screenSize.md ? 'column' : 'row'};
`
const CoverExamBanner = styled.ImageBackground`
  flex: ${({theme}) => theme.screenSize.lg ? theme.screenSize.md ? '1' : '3' : '2'};
  height: 100%;
  align-items: center;
  justify-content: center;
`
const CoverExamContentWrapper = styled.ImageBackground`
  display: flex;
  flex-direction: column;
  max-width: 720px;
  padding: ${({theme}) => theme.screenSize.md ? '20px' : '60px' };
`
const CoverExamDetail = styled.Text`
  font-family: ${Fonts.brand};
  color: ${Colors.white};
  font-size: ${({theme}) => theme.screenSize.md ? '14px' : '20px' };
  max-width: 400px;
`
const CoverExamSubTitle = styled.Text`
  font-family: ${Fonts.brand2};
  font-size: ${({theme}) => theme.screenSize.md ? '14px' : '22px' };
  color: #fff;
  flex-shrink: 1;
`
const CoverExamTitle = styled.Text`
  font-family: ${Fonts.brand2};
  font-size: ${({theme}) => theme.screenSize.md ? '22px' : '42px' };
  color: #fff;
  flex-shrink: 1;
  margin-bottom: 15px;
`
const TextBlackBg = styled.Text`
  background: ${Colors.black};
  padding-left: 10px;
  padding-right: 10px;
`
const TextBrandBg = styled.Text`
  background: ${Colors.brand};
  padding-left: 10px;
  padding-right: 10px;
`
const AccountWrapper = styled.View`
  flex: ${({theme}) => theme.screenSize.lg ? '2' : '1'};
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};
`

export default CoverExamScreen;