import React from 'react';
import styled from "styled-components/native";
import Colors from "../../themes/Colors";
import Fonts from "../../themes/Fonts";

const Title = styled.Text`
  font-family: ${Fonts.brand};
  color: ${Colors.slate600};
  text-align: center;
`
const Loading = styled.ActivityIndicator`
  margin-right: 10px; 
`

const Button = styled.TouchableOpacity`
  border-radius: 8px;
  color: ${Colors.black};
  font-family: ${Fonts.brand};
  height: 40px;
  border: 1px solid ${Colors.slate300};
  padding: 5px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

function GhostButton({ title, onPress, loading, disabled, leftElement, rightElement, titleStyle, ...props }) {
  return (
    <Button 
      onPress={onPress} 
      disabled={disabled || loading}
      style={{
        opacity: disabled || loading ? 0.6 : 1,
      }}
      {...props}
    >
      { loading && <Loading color={Colors.black}/> }
      { typeof leftElement == 'function' && leftElement() }
      <Title style={{...titleStyle}}>{title}</Title>
      { typeof rightElement == 'function' && rightElement() }
    </Button>
  );
}

export default GhostButton;