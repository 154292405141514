import React, { useState, useEffect, useContext } from 'react';
import { Text, Platform } from 'react-native';
import Constants from 'expo-constants';
import styled, { useTheme } from 'styled-components/native'
import Images from '../themes/Images'
import Colors from '../themes/Colors'
import Fonts from '../themes/Fonts'

import AuthPanel from '../components/Account/AuthPanel';
import { OfflineContext } from '../contexts/OfflineContext'
import DB from '../libs/DB'
import { OFFLINE_MODE } from '../utils/Constant'

function HomeScreen({navigation}) {
  const theme = useTheme()
  const { isOfflineMode, setIsOfflineMode, } = useContext(OfflineContext)

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', async () => {
      const _isOfflineMode = await DB.get(OFFLINE_MODE)
      setIsOfflineMode(_isOfflineMode)
    });
    return unsubscribe;
  },[navigation])

  return (
    <HomeWrapper>
      <HomeBanner source={ theme.screenSize.lg ? Images.mainBgMd : Images.mainBg} resizeMode="cover" >
        <HomeContentWrapper>
          <Logo source={Images.eefXBootcampLogo} resizeMode="contain" />
          <HomeSubTitle><TextBlackBg>ADAPTIVE TEST</TextBlackBg></HomeSubTitle>
          <HomeTitle><TextBrandBg>ข้อสอบวัดความสามารถ</TextBrandBg></HomeTitle>
          <HomeTitle><TextBrandBg>ทางคณิตศาสตร์</TextBrandBg></HomeTitle>
          <HomeDetail>
            <Text style={{fontFamily: "Mitr_500Medium"}}>กสศ. </Text>
            <Text>มีภารกิจในการช่วยเหลือดูแลกลุ่มเป้าหมายซึ่งเป็นผู้ขาดแคลนทุนทรัพย์ หรือด้อยโอกาสนับตั้งแต่แรกเกิดจนถึงวัยแรงงานให้ได้รับโอกาสทางการศึกษา เพื่อบรรเทาความยากจนอันเป็นรากเหง้าของปัญหาอื่นๆ ซึ่งหากแก้ไม่ได้ ปัญหานี้จะส่งทอดวนเวียนไปข้ามชั่วคน จากพ่อแม่ ส่งต่อไปถึงรุ่นลูกรุ่นหลานได้เพราะมีกลุ่มคนที่เข้าไม่ถึงการศึกษา หรือได้รับการศึกษาที่มีคุณภาพต่างกัน</Text>
          </HomeDetail>
        </HomeContentWrapper>
      </HomeBanner>
      <AuthWrapper>
        <VersionText>{Constants.manifest.version}</VersionText>
        <OfflineMode offlineMode={isOfflineMode}>{isOfflineMode === true ? 'OFFLINE MODE' : 'ONLINE MODE'}</OfflineMode>
        <AuthPanel />
      </AuthWrapper>
    </HomeWrapper>
  );
}

export default HomeScreen;

//Mitr_300Light | Mitr_500Medium | Chonburi_400Regular
const HomeWrapper = styled.View`
  flex: 1;
  justify-content: center;
  flex-direction: row;
  flex-direction: ${({theme}) => theme.screenSize.md ? 'column' : 'row'};
`
const HomeBanner = styled.ImageBackground`
  flex: ${({theme}) => 
    theme.screenSize.md
    ? '1'
    : theme.screenSize.lg 
      ? '3' 
      : '2'
  };
  height: 100%;
  align-items: center;
  justify-content: center;
`
const HomeContentWrapper = styled.View`
  display: flex;
  max-width: ${({theme}) => theme.screenSize.lg ? '100%' : '720px'};
  padding: 20px;
  margin-right: ${({theme}) => theme.screenSize.lg ? '0' : '160px'};
  flex-direction: column;
`
const Logo = styled.Image`
  width: ${({theme}) => theme.screenSize.md ? '180px' : '250px'};
  height: ${({theme}) => theme.screenSize.md ? '20px' : '27px'};
  margin-bottom: ${({theme}) => theme.screenSize.md ? '10px' : '25px'};
`
const HomeDetail = styled.Text`
  font-family: ${Fonts.brand};
  color: ${Colors.white};
  margin-top: 20px;
  max-width:  ${({theme}) => theme.screenSize.lg ? '100%' : '400px'};
  display:  ${({theme}) => theme.screenSize.md ? 'none' : Platform.OS === 'web' ? 'block' : 'flex'};
`
const HomeSubTitle = styled.Text`
  font-family: ${Fonts.brand2};
  font-size: ${({theme}) => theme.screenSize.md ? '18px' : '22px'};
  color: #fff;
  flex-shrink: 1;
`
const HomeTitle = styled.Text`
  font-family: ${Fonts.brand2};
  font-size: ${({theme}) => theme.screenSize.md
    ? '22px' 
    : theme.screenSize.lg
      ? '36px'
      : '36px'
  };
  color: #fff;
  flex-shrink: 1;
`
const TextBlackBg = styled.Text`
  background: ${Colors.black};
  padding-left: 10px;
  padding-right: 10px;
`
const TextBrandBg = styled.Text`
  background: ${Colors.brand};
  padding-left: 10px;
  padding-right: 10px;
`
const AuthWrapper = styled.View`
  flex: ${({theme}) => 
    theme.screenSize.md
    ? '3'
    : theme.screenSize.lg 
      ? '2' 
      : '1'
  };
  height: 100%;
  align-items: center;
  justify-content: center;
  background: ${Colors.white};
`
const VersionText = styled.Text`
  position: absolute;
  right:15px;
  bottom: 15px;
  font-size: 12px;
  color: #666;
`

const OfflineMode = styled.Text`
  position: absolute;
  right:15px;
  top: 15px;
  font-size: 10px;
  color: #fff;
  border-radius: 10px;
  padding: 5px 10px 2px;
  font-weight: bold;
  line-height: 10px;
  align-content: center;
  background-color: ${({offlineMode}) => offlineMode === true ? Colors.brand : Colors.slate300 } ;
`
