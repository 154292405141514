import React from 'react';
import { TouchableOpacity } from 'react-native';
import styled from 'styled-components/native';
import { LinearGradient } from 'expo-linear-gradient'
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';

let GradientView = GradientView = styled(LinearGradient)`
  border-radius: 6px;
  padding: 8px 20px;
`

const Title = styled.Text`
  font-family: ${Fonts.brandBold};
  color: ${Colors.white};
  text-align: center;
`
const Loading = styled.ActivityIndicator`
  position: absolute;
  left: 10px;
  top: 10px;
`

function BrandButton({title, onPress, loading, disabled, ...props }) {
  return (
    <TouchableOpacity 
      accessible={true}
      accessibilityLabel={title}
      accessibilityRole="button"
      onPress={onPress}
      disabled={disabled || loading}
      {...props}
    >
      <GradientView
        colors={[Colors.purple600, Colors.indigo600]}
        start={{ x: 0, y: 0 }}
        end={{ x: 1, y: 1 }}
        style={{opacity: disabled || loading ? 0.6 : 1}}
      >
        { loading && <Loading color={Colors.white}/> }
        <Title>{title}</Title>
      </GradientView>
    </TouchableOpacity>
  );
}

export default BrandButton;