const Colors = {
  brand: "#00AD71",
  brand2: "#0029B1",
  white: "#ffffff",
  black: "#000000",
  slate50: "#f8fafc",
  slate100: "#f1f5f9",
  slate200: "#e2e8f0",
  slate300: "#cbd5e1",
  slate400: "#94a3b8",
  slate500: "#64748b",
  slate600: "#475569",
  slate700: "#334155",
  slate800: "#1e293b",
  slate900: "#0f172a",
  red500: "#ef4444",
  green500: "#22c55e",
  purple600: "#9333ea",
  purple800: "#6b21a8",
  indigo600: "#4f46e5",
  indigo800: "#3730a3",
}

export default Colors