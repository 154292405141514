import React from 'react';
import { View, Platform } from 'react-native';
import styled from 'styled-components/native';
import Colors from '../../themes/Colors';
import Html from '../Commons/Html';

const thaiCharactors = ['ก','ข','ค','ง','จ','ฉ','ช','ซ','ฌ','ญ','ฎ','ฏ','ฐ','ฑ','ฒ','ณ','ด','ต','ถ','ท','ธ','น','บ','ป','ผ','ฝ','พ','ฟ','ภ','ม','ย','ร','ล','ว','ศ','ษ','ส','ห','ฬ','อ','ฮ']

const ChoiceItem = styled.TouchableOpacity`
  border: 2px solid;
  border-color: ${({selected}) => selected ? Colors.brand2 : Colors.slate300 };
  padding: 6px 15px 0 45px;
  padding-top: ${ Platform.OS === 'web' ? '0px' : '6px' };
  border-radius: 10px;
  margin-bottom: 20px;
`
const ChoiceDot = styled.Text`
  background: ${({selected}) => selected ? Colors.brand2 : Colors.slate300 };
  border-radius: 14px;
  width: 24px;
  height: 24px;
  color: ${({selected}) => selected ? '#ffffff' : Colors.brand2 };
  position: absolute;
  left: 12px;
  top: 15px;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 24px;
`

function Choice({ index, option, selected, handleSelect, width }) {
  return (
    <ChoiceItem selected={selected} onPress={()=>{ handleSelect(index) }} >
      <ChoiceDot selected={selected} >{thaiCharactors[index]}</ChoiceDot>
      <Html data={option} width={width-70} disableImageClickable={true} />
    </ChoiceItem>
  );
}

export default Choice;