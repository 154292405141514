export const LOGIN_CODE = 'loginCode'
export const ALL_CODE_PACKAGES = 'allCodePackages'
export const CODE_PACKAGE_PREFIX = 'codePackage_'
export const ATTEMPT_DATA = 'attemptData'
export const SUBJECT_DATA = 'subjectData'
export const LIST_DATA = 'listData'
export const SURVEY_LIST_DATA = 'surveyListData'
export const OFFLINE_MODE = 'offlineMode'
export const SURVEY_MAP_LIST = {
    survey1: 'age',
    survey2: 'sliblings',
    survey3: 'family_members',
    survey4: 'guardian_occupation',
    survey5: 'household_income',
    survey6: 'highest_education',
    survey7: 'dream_job',
    survey8: 'eef_funded',
}
