import React, { useContext } from 'react';
import styled from 'styled-components/native'
import { MaterialIcons } from '@expo/vector-icons'; 
import Colors from '../themes/Colors'
import Fonts from '../themes/Fonts'
import { useForm, Controller } from "react-hook-form";
import useAPI from '../hooks/useAPI';

import GhostButton from '../components/Commons/GhostButton';
import BrandButton from '../components/Commons/BrandButton';
import SelectList from '../components/Form/SelectList';

import { AuthContext } from '../contexts/AuthContext'

 //Mitr_300Light | Mitr_500Medium | Chonburi_400Regular
const AddEditAccountWrapper = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  background: ${Colors.white};
`
const BackButton = styled(GhostButton)`
  position: absolute;
  left: 20px;
  top: 20px;
`

const ScrollViewContainer = styled.ScrollView`
  flex: 1;
  background-color: #f00;
  width: 600px;
  max-width: 600px;
`

const Container = styled.View`
  background: #fff;
  width: 100%;
  padding: 40px 20px;
  justify-content: center;
  align-items: center;
`
const ActionWrapper = styled.View`
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 320px;
  margin-top: 40px;
`

const Title = styled.Text`
  flex-grow: 1;
  font-family: ${Fonts.brandBold};
  color: ${Colors.brand2};
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
`
const FormGroup = styled.View`
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.slate100};
  width: 100%;
`

const FormGroupCol = styled.View`
  flex-direction: row;
  flex: 1;
`

const LabelContainer = styled.View`
  flex: 2;
  padding: 10px;
  justify-content: center;
`
const InputContainer = styled.View`
  flex: 3;
  padding: 10px;
  justify-content: center;
`
const Lable = styled.Text`
  text-align: right;
  font-family: ${Fonts.brandBold};
  color: ${Colors.slate600};
`
const InputText = styled.TextInput`
  width: 100%;
  border: 1px solid ${Colors.slate300};
  border-radius: 6px;
  padding: 6px 15px;
`
const ErrorText = styled.Text`
  color: #f00;
  font-family: ${Fonts.brand};
  margin-top: 5px;
`

const TextDisplay = styled.Text`
  padding: 6px 0;
  font-family: ${Fonts.read};
`

function AddEditAccountScreen({route, navigation, handleCallback}) {
  const { subject, client, selectSubject, fetchSubject, fetchSubjects } = useContext(AuthContext)
  const { api } = useAPI()

  const { control, handleSubmit, formState: { errors } } = useForm({
    defaultValues: {
      first_name: "",
      last_name: "",
      room: "",
      national_id: "",
      ...subject,
      gender_id: subject?.gender?.id ? subject.gender.id : "",
      grade_id: subject?.grade?.id ? subject.grade.id : "",
    }
  });
  const onSubmit = async (data) => {
    try {
      if (!subject) {
        // Create new subject

        // Find current year
        let year_id = null
        const yearsRes = await api.get(`/lists`, {
          type: 'year',
          limit: 100,
        })
        if ( yearsRes?.data?.data ) {
          const currentYear = new Date().getFullYear() + 543
          const year = yearsRes.data.data.find( item => item.ivalue == currentYear )
          year_id = year?.id
        }
        if (year_id) {
          data.year_id = year_id
        }

        const resSubject = await api.post('/subjects', {
          ...data,
          client_id: client?.id,
          status: "pending",
        })
        if (resSubject?.data) {
          await selectSubject(resSubject?.data?.id)
          navigation.navigate("ExamCover")
          return
        }
      } else {
        // Edit subject
        const  resSubject = await api.patch(`/subjects/${subject.id}`, {
          ...data,
        })
        await fetchSubject(subject.id)
        await fetchSubjects(client.id)
      }

      if (typeof handleCallback == "function") {
        handleCallback()
      }
      navigation.goBack()

    } catch (error) {
      console.log(error)
    }
    
  }

  const handleBack = () => {
    navigation.goBack()
  }

  return (
    <AddEditAccountWrapper>
      <BackButton title="ย้อนกลับ" onPress={handleBack} leftElement={() => <MaterialIcons name="chevron-left" size={24} color={Colors.slate600} style={{marginRight: 5}} />} />
      <ScrollViewContainer>
        <Container>
          <Title>{ subject ? "รายละเอียดผู้สอบ" : "เพิ่มรายชื่อผู้สอบ" }</Title>
          
          <FormGroup>
            <LabelContainer>
              <Lable>ชื่อ</Lable>
            </LabelContainer>
            <InputContainer>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputText 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value} 
                  />
                )}
                name="first_name"
              />
              {errors.first_name && <ErrorText>กรุณากรอกข้อมูล</ErrorText>}
            </InputContainer>
          </FormGroup>

          <FormGroup>
            <LabelContainer>
              <Lable>นามสกุล</Lable>
            </LabelContainer>
            <InputContainer>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputText 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value} 
                  />
                )}
                name="last_name"
              />
              {errors.last_name && <ErrorText>กรุณากรอกข้อมูล</ErrorText>}
            </InputContainer>
          </FormGroup>

          <FormGroup>
            <LabelContainer>
              <Lable>ชื่อโรงเรียน</Lable>
            </LabelContainer>
            <InputContainer>
              <TextDisplay>{client?.name}</TextDisplay>
            </InputContainer>
          </FormGroup>

          <FormGroup>
            <LabelContainer>
              <Lable>ห้อง</Lable>
            </LabelContainer>
            <InputContainer>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputText 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value} 
                  />
                )}
                name="room"
              />
            </InputContainer>
          </FormGroup>

          <FormGroup>
            <LabelContainer>
              <Lable>ชั้น</Lable>
            </LabelContainer>
            <InputContainer>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectList 
                    type="grade"
                    placeholder="ชั้น"
                    noOptionsMessage="ไม่พบข้อมูล"
                    value={value}
                    searchable={true}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
                name="grade_id"
              />
              {errors.grade_id && <ErrorText>กรุณากรอกข้อมูล</ErrorText>}
            </InputContainer>
          </FormGroup>

          <FormGroup>
            <LabelContainer>
              <Lable>เพศ</Lable>
            </LabelContainer>
            <InputContainer>
              <Controller
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field: { onChange, onBlur, value } }) => (
                  <SelectList 
                    type="gender"
                    placeholder="เพศ"
                    noOptionsMessage="ไม่พบข้อมูล"
                    value={value}
                    searchable={true}
                    onChange={onChange}
                    onBlur={onBlur}
                  />
                )}
                name="gender_id"
              />
              {errors.gender_id && <ErrorText>กรุณากรอกข้อมูล</ErrorText>}
            </InputContainer>
          </FormGroup>

          <FormGroup>
            <LabelContainer>
              <Lable>เลขบัตรประชาชน</Lable>
            </LabelContainer>
            <InputContainer>
              <Controller
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <InputText 
                    onBlur={onBlur}
                    onChangeText={onChange}
                    value={value} 
                    placeholder="x-xxxx-xxxxx-xx-x"
                  />
                )}
                name="national_id"
              />
            </InputContainer>
          </FormGroup>

          <ActionWrapper>
            <BrandButton
              onPress={handleSubmit(onSubmit)}
              style={{
                width: "100%",
                marginBottom: 15,
              }} title="ตกลง"
            />
            <GhostButton
              onPress={handleBack}
              style={{
                width: "100%",
                marginBottom: 15,
              }} 
              titleStyle={{
                flex: 1,
              }}
              title="ยกเลิก"
            />
          </ActionWrapper>

        </Container>
      </ScrollViewContainer>
    </AddEditAccountWrapper>
  );
}

export default AddEditAccountScreen;