import AppFunction from "./AppFunction";

const getQueryStringParams = path => {
  const query = path.split("?")[1]
  return query
      ? (/^[?#]/.test(query) ? query.slice(1) : query)
          .split('&')
          .reduce((params, param) => {
                  let [key, value] = param.split('=');
                  params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                  return params;
              }, {}
          )
      : {}
};

const findUUID = path => {
  let uuid = null
  let route = path
  const regex = /\b[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}\b/g
  const findUuid = path.match(regex)
  if (findUuid && Array.isArray(findUuid)) {
    uuid = findUuid[0]
    route = route.replace(regex, '{id}')
  }
  return {
    uuid,
    route,
  }
}

const stripTrailingSlash = (str) => {
  return str.endsWith('/') ?
      str.slice(0, -1) :
      str;
};

const request = async (method, path, params, headers) => {
  const routeObject = findUUID(stripTrailingSlash(path.split("?")[0]))
  const { route, uuid } = routeObject
  const pathParam = getQueryStringParams(path)
  console.log('>>>',method, route, pathParam, params, headers, uuid)
  if (typeof AppFunction[method][route] === 'function' ) {
    try {
      const response = await AppFunction[method][route](pathParam, params, headers, uuid )
      console.log('<==', route, {data: response})
      return {
        data: response,
      }  
    } catch (error) {
      Promise.reject(error)
    }
  } else {
    Promise.reject(`Path ${method} ${route} not found`)
  }
}

const APILocal = {
  request
}

export default APILocal