import { useWindowDimensions } from 'react-native'

const SCREEN_MEDIA_QUERY = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536,
}
function useScreenSize() {
  let screenMediaQuery = {}

  Object.keys(SCREEN_MEDIA_QUERY).forEach(key => {
    screenMediaQuery[key] = useWindowDimensions().width <= SCREEN_MEDIA_QUERY[key]
  })
  return screenMediaQuery
}

export default useScreenSize