import React, { createContext, useState, useEffect } from 'react'
import useAPI from '../hooks/useAPI'
import { v4 as uuidv4 } from 'uuid';

export const ExamContext = createContext()

export const ExamProvider = (props) => {
  const { api } = useAPI()

  const [graph, setGraph] = useState(null)
  const [nodeData, setNodeData] = useState(false)
  const [testId, setTestId] = useState(null)
  const [test, setTest] = useState(null)
  const [testLoading, setTestLoading] = useState(null)
  const [attempt, setAttempt] = useState(null)
  const [items, setItems] = useState(null)
  const [questions, setQuestions] = useState(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0)
  const [answers, setAnswers] = useState(null)
  const [answerLoading, setAnswerLoading] = useState(null)
  const [currentChapterIndex, setCurrentChapterIndex] = useState(0)
  const [score, setScore] = useState(null)
  const [isTestMode, setIsTestMode] = useState(false)
  const [ability, setAbility] = useState(null)

  useEffect(() => {
    if (!testId) {
      setTest(null)
      setItems(null)
      setQuestions(null)
      return
    }
    (async() => {
      setTestLoading(true)
      try {
        const resTest = await api.get(`/tests/${testId}`)
        setTest(resTest?.data)
        const resItems = await api.get(`/items`, {
          test_id: testId,
          sort: "sort_order",
        })
        if (resItems?.data?.data) {
          setItems(resItems.data.data)
          const _questions = resItems?.data?.data.map(item => {
            return {
              ...item?.question,
              fvalue: item?.fvalue,
              ivalue: item?.ivalue,
              options: item?.question_options,
              text: item?.question_text,
              type: item?.question_type,
              uid: item?.question_uid,
            }
          })
          setQuestions(_questions)
        }

        setCurrentQuestionIndex(0)
        setAnswers(null)

      } catch (error) {
        console.warn(error)
      }
      setTestLoading(false)
    })()
  },[testId])

  const recusiveBuildNode = (ids, edges, nodeData) => {
    nodeData.push(ids)
    const nextIds = [...new Set(
      edges.filter(edge => {
        return ids.includes(edge.src?.id)
      }).map( edge => edge.dst?.id )
    )]
    if (nextIds && nextIds.length > 0) {
      return recusiveBuildNode(nextIds, edges, nodeData)
    }
    return nodeData
  }
  const fetchGraph = async (graphId, attempt_id) => {
    const resGraph = await api.get(`/graphs/${graphId}`, {
      attempt_id,
    })
    const _graph = resGraph?.data ? resGraph.data : null
    if (!_graph || !_graph.edges || !_graph.root?.id) {
      setNodeData(null)
      setGraph(null)
      return
    }
    setGraph(_graph)
    const _nodeIdData = recusiveBuildNode([_graph.root.id], _graph.edges, [])
    const _nodeData = _nodeIdData.map(nodeCol => nodeCol.map(nodeId => {
      return {
        test_id: nodeId,
        id: uuidv4(),
      }
    }))
    setNodeData(_nodeData)
    return {
      graph: _graph,
      nodeData: _nodeData,
    }
  }

  const fetchScore = async (attempt_id) => {
    try {
      const [resScore, resAbility] = await Promise.all([
        api.get(`/irt/score`, { attempt_id }),
        api.get(`/irt/ability_estimate`, { attempt_id }),
      ])
      if (resScore?.data) {
        setScore(resScore?.data)
      }
      if (resAbility?.data) {
        setAbility(resAbility?.data)
      }
    } catch (error) {
      console.warn(error) 
    }
  }

  const checkIsFinish = async (attempt_id, node_data) => {
    const responseRes = await api.get(`/responses`, {
      attempt_id,
    })
    return responseRes?.data?.data?.length === node_data.length
  }

  const startExam = async (subject, codeState ) => {
    try {
      let _attempt = null
      const res = await api.get(`/attempts?code_id=${codeState?.id}&subject_id=${subject?.id}`)
      _attempt = res?.data?.data && res?.data?.data[0] ? res?.data?.data[0] : null
      if (!_attempt) {
        // New subject
        const resAttempt = await api.post(`/attempts`,{
          code_id: codeState?.id,
          subject_id: subject?.id,
        })
        if (resAttempt?.data) {
          _attempt = resAttempt?.data
        }
      }
      // Check is test mode will not collect data
      if (_attempt?.tested == true) {
        setIsTestMode(true)
      }
      setAttempt(_attempt)

      // Load graph and find latest test
      const { graph: _graph, nodeData: _nodeData } = await fetchGraph(codeState?.graph?.id, _attempt?.id)
      if (!_graph) return
      const lastTestId = _graph?.latest_test?.id
      const rootTestId = _graph?.root?.id

      if (!lastTestId) {
        // Not do test yet
        setTestId(rootTestId)
        setCurrentChapterIndex(0)
        return
      }

      // Find next test
      const nextNode = await findBestNode(lastTestId, _attempt.id, _graph)
      const isFinish = await checkIsFinish(_attempt.id, _nodeData)

      if (isFinish === true && !nextNode?.test_id) {
        const testChapterLength = _nodeData.length
        if (!subject?.locus) {
          // Show locus
          setCurrentChapterIndex(testChapterLength)
          return
        }

        const isSurveyDone = checkAllSurveyIsDone(subject)
        if (!isSurveyDone) {
          // Show survey
          setCurrentChapterIndex(testChapterLength + 1)
          return
        }
        
        // Show result
        setCurrentChapterIndex(testChapterLength + 2)
        return
      }

      if (nextNode?.test_id) {
        setTestId(nextNode.test_id)
        const nextChapterIndex = _nodeData.findIndex((col, _) => {
          const node = col.find(node => node?.test_id === nextNode.test_id)
          if (node) {
            return true
          }
          return false
        })
        setCurrentChapterIndex( nextChapterIndex )
        return
      }

    } catch (error) {
      console.warn(error)
    }
  }

  const checkAllSurveyIsDone = (subject) => {
    const surveyKey = Object.keys(subject).filter((key) => {
      return key.startsWith('survey') && !key.endsWith('_name')
    })
    return surveyKey.every((key) => {
      if ( subject[key] || subject[`${key}_name`] ) {
        return true
      }
      return false
    })
  }

  const exitExam = () => {
    setAttempt(null)
    setGraph(null)
    setTestId(null)
    setAnswers(null)
    setCurrentChapterIndex(0)
  }

  const doAnswer = (answer, questionIndex, type) => {
    const _answer = {
      questionIndex,
      answer,
      item_id: items[questionIndex]?.id, 
    }
    if (type == 'fill_float') {
      _answer.fvalue = parseFloat(answer)
    } else {
      _answer.ivalue = parseInt(answer)
    }
    const _answers = answers == null ? [] : [...answers]
    _answers[questionIndex] = _answer

    setAnswers(_answers)
  }

  const doResponseAnswer = async () => {
    if (!answers || !attempt || !test) return
    setAnswerLoading(true)
    try {
      await api.post(`/responses`, {
        attempt_id: attempt?.id,
        test_id: test?.id,
        answers,
      })
    } catch (error) {
      console.warn(error)
    }

    try {
      const nextNode = await findBestNode(test?.id, attempt?.id, graph)
      const isFinish = await checkIsFinish(attempt?.id, nodeData)
      
      if (isFinish) {
        setCurrentChapterIndex( currentChapterIndex+1 )
      } else if (nextNode?.test_id) {
        setTestId(nextNode.test_id)
        setCurrentChapterIndex( currentChapterIndex+1 )
      }
      
    } catch (error) {
      console.warn(error)
    }
    setAnswerLoading(false)
  }

  const findBestNode = async ( testId, attemptId, graph) => {
    if (!testId) return null
    const posibleNode = graph.edges.filter(edge => edge.src.id === testId)
    try {
      const promiseIrt = posibleNode.map( (edge) => {
        return new Promise( async (resolve, reject) => {
          try {
            const res = await api.get(`/irt/test_info`, {
              attempt_id: attemptId,
              test_id: edge?.dst?.id,
            })
            if (res?.data) {
              return resolve({
                test_id: edge?.dst?.id,
                irt: res?.data
              })
            }
          } catch (error) {
            console.warn(error) 
            reject()
          }
        })
      });
      const resIrts = await Promise.all(promiseIrt)

      if (resIrts) {
        const bestNode = resIrts.reduce((acc, node)=>{
          if (!acc) {
            return node
          }
          if (node.irt >= acc.irt ) {
            return node
          }
          return acc
        }, null)
        return bestNode
      }
      
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <ExamContext.Provider value={{
      attempt,
      test,
      testLoading,
      items,
      questions,
      graph,
      nodeData,
      startExam,
      exitExam,
      currentQuestionIndex, 
      setCurrentQuestionIndex,
      doAnswer,
      answers,
      answerLoading,
      doResponseAnswer,
      findBestNode,
      currentChapterIndex, 
      setCurrentChapterIndex,
      score,
      isTestMode,
      ability,
      fetchScore,
      // For test
      setTestLoading,
      setTestId,
      setQuestions,
    }}>
      {props.children}
    </ExamContext.Provider>
  )
}