import React, { useState, useEffect, useContext } from 'react';
import { Alert, Platform } from 'react-native'
import styled from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { ExamContext } from '../contexts/ExamContext'
import { AuthContext } from '../contexts/AuthContext'

import Colors from '../themes/Colors';

import GhostButton from '../components/Commons/GhostButton';
import Exam from '../components/Exam';
import BottomBar from '../components/BottomBar';

const TopLeftPanel = styled.View`
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 10;
`

const ExamWrapper = styled.View`
  flex: 1;
  flex-direction: row;
`
const ExitButton = styled(GhostButton)`
  background: ${Colors.white};
`

function TestExamScreen({ navigation, route }) {

  const { currentChapterIndex, setCurrentChapterIndex, setTestId, ...examContext } = useContext(ExamContext)
  const { loginWithCode } = useContext(AuthContext)

  useEffect(() => {
    if (!route || !route.params || !route.params.id ) {
      navigation.navigate('Home', {
        reset: true,
      })
    }
    (async ()=>{
      await loginWithCode('sample123')
      setTestId(route.params.id)
    })()
  }, [])


  const handleExitExam = () => {
    navigation.navigate('Home', {
      reset: true,
    })
  }

  const renderContent = () => {
    return <Exam />
  }

  return (
    <ExamWrapper>
      <TopLeftPanel>
        <ExitButton 
          onPress={handleExitExam} 
          title="ออกจากการทำข้อสอบ"
          leftElement={()=> <MaterialIcons name="exit-to-app" size={24} color={Colors.slate600} style={{marginRight: 5}} />}
        />
      </TopLeftPanel>
      { renderContent() }
      <BottomBar activeChapter={currentChapterIndex+1}/>
    </ExamWrapper>
  );
}

export default TestExamScreen;