import React from 'react';
import styled from "styled-components/native";
import Colors from "../../themes/Colors";
import Fonts from "../../themes/Fonts";

const Title = styled.Text`
  font-family: ${Fonts.brand};
  color: ${Colors.black};
  text-align: center;
  flex-grow: 1;
`
const Loading = styled.ActivityIndicator` 
  margin-right: 10px;
`

const Button = styled.TouchableOpacity`
  border-radius: 8px;
  color: ${Colors.black};
  font-family: ${Fonts.brand};
  height: 40px;
  padding: 5px 10px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${Colors.white};
  min-width: 100px;
`

function WhiteButton({ title, onPress, loading, disabled, leftElement, rightElement }) {
  return (
    <Button 
      onPress={onPress} 
      disabled={disabled || loading}
      style={{opacity: disabled || loading ? 0.6 : 1}}
    >
      { loading && <Loading color={Colors.slate600}/> }
      { typeof leftElement == 'function' && leftElement() }
      <Title>{title}</Title>
      { typeof rightElement == 'function' && rightElement() }
    </Button>
  );
}

export default WhiteButton;