import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native'
import styled from 'styled-components/native';
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import CheckBox from "expo-checkbox";

const CheckListContainer = styled.View`
  background: ${({isOdd}) => isOdd ? Colors.slate50 : Colors.white};
  padding: 20px;
  flex-direction: row;
`
const CheckListNumberContainer = styled.View`
  flex: 1;
`
const CheckListTitleContainer = styled.View`
  flex: 10;
`

const CheckListNumber = styled.Text`
  font-family: ${Fonts.read};
  font-size: 16px;
  margin-right: 10px;
  line-height: 30px;
  text-align: right;
`
const CheckListTitle = styled.Text`
  flex-grow: 1;
  font-family: ${Fonts.read};
  font-size: 16px;
  padding-right: 20px;
  line-height: 30px;
`

const CheckListInputWrapper = styled.TouchableOpacity`
  flex-shrink: 1;
  width: 60px;
  min-width: 60px;
  align-items: center;
  flex: 1;
`
const CheckListInputLabel = styled.Text`
  text-align: center;
  font-family: ${Fonts.brand};
  font-size: 11px;
  color: ${Colors.brand2};
  flex: 1;
`

function CheckListItem({title, index, handleResponse}) {

  const [value, setValue] = useState(null)
  const itemId = index + 1

  useEffect( () => {
    handleResponse(index, value)
  }, [value] )

  return (
    <CheckListContainer isOdd={index % 2}>
      
      <CheckListNumberContainer>
        <CheckListNumber>{itemId})</CheckListNumber>
      </CheckListNumberContainer>
      <CheckListTitleContainer>
        <CheckListTitle>{title}</CheckListTitle>
      </CheckListTitleContainer>
      <CheckListInputWrapper onPress={()=>{ setValue("1") }}>
        <CheckBox 
          style={{zoom: 1.5}}
          color={Colors.brand2}
          value={ value == "1" }
          onValueChange={(isCheck)=>{
            if (isCheck) {
              setValue("1")
            }
          }} 
        />
        <CheckListInputLabel>ใช่</CheckListInputLabel>
      </CheckListInputWrapper>
      <CheckListInputWrapper onPress={()=>{ setValue("0") }}>
        <CheckBox 
          style={{zoom: 1.5}}
          color={Colors.brand2}
          value={ value == "0" }
          onValueChange={(isCheck)=>{
            if (isCheck) {
              setValue("0")
            }
          }}
        />
        <CheckListInputLabel>ไม่ใช่</CheckListInputLabel>
      </CheckListInputWrapper>
    </CheckListContainer>
  );
}

export default CheckListItem;