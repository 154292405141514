const Images = {
  eefXBootcampLogo: require("../../assets/images/main/eef-x-bootcamp-logo.png"),
  mainBg: require("../../assets/images/main/main-bg.jpg"),
  mainBgMd: require("../../assets/images/main/main-bg-md.jpg"),
  bgCard: require("../../assets/images/onboarding/bg-card.jpg"),
  resultBg: require("../../assets/images/result/result-bg.jpg"),
  bgBar: require("../../assets/images/share/bg-bar.jpg"),
  graphicBar: require("../../assets/images/share/graphic-bar.png"),
}

export default Images