import React from 'react';
import styled from 'styled-components/native';
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import Choice from './Choice';

const ChoiceTitle = styled.Text`
  font-size: 14px;
  font-family: ${Fonts.brandBold};
  color: ${Colors.slate500};
  margin-bottom: 15px;
`
const ChoiceContainer = styled.View`
  padding-bottom: 100px;
`

function Choices({options, optionIdSelected, handleSelect, width}) {

  if (!options) return null

  return (
    <>
      <ChoiceTitle>เลือกคำตอบ</ChoiceTitle>
      <ChoiceContainer>
        { options.map((option, index) => (
          <Choice 
            key={`choice-${index}`}
            index={index}
            option={option}
            selected={optionIdSelected == index}
            handleSelect={handleSelect} 
            width={width}
          />
        )) }
      </ChoiceContainer>
    </>
  );
}

export default Choices;