import React, { useState, useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons'; 
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import WhiteButton from '../Commons/WhiteButton';
import BrandButton from '../Commons/BrandButton';
import CheckListItem from './CheckListItem';
import { AuthContext } from '../../contexts/AuthContext'
import { ExamContext } from '../../contexts/ExamContext'
import useAPI from '../../hooks/useAPI';

const BOTTOM_BAR_HEIGHT = 80

const QuestionContainer = styled.View`
  background: #fff;
  width: ${({theme})=>theme.screenSize.md ? `100%` : `700px`};
  padding-bottom: ${({theme})=>theme.screenSize.md ? `80px` : `200px`};
`
const QuestionAction = styled.View`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: ${({theme})=>theme.screenSize.md ? `120px` : `${BOTTOM_BAR_HEIGHT}px`};
  z-index: 20;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const QuestionHeader = styled.View`
  position: absolute;
  top: 0;
  left: 0;
  height: 80px;
  background: ${Colors.white};
  width: 100%;
  z-index: 10;
  border-bottom-color: ${Colors.slate300};
  border-bottom-width: 2px;
  align-items: center;
  justify-content: center;
  padding-right: ${({theme})=>theme.screenSize.md ? `180px` : `0`};
`
const QuestionHeaderContainer = styled.View`
  width: ${({theme})=>theme.screenSize.md ? `100%` : `600px`};
  align-items: center;
  flex-direction: row;
  padding: 0 20px;
`
const QuestionTitle = styled.Text`
  flex-grow: 1;
  font-family: ${Fonts.brandBold};
  color: ${Colors.brand2};
  text-align: left;
`
const QuestionLabel = styled.Text`
  flex-shrink: 1;
  font-family: ${Fonts.brandBold};
  color: ${Colors.green500};
  text-align: center;
  width: 65px;
  min-width: 65px;
`
const LocusOfControlWrapper = styled.View`
  flex: 1;
  background: ${Colors.slate100};
`

const ScrollViewContainer = styled.ScrollView`
  padding: ${({theme})=>theme.screenSize.md ? `80px 0 0` : `80px 40px 0`};
  flex: ${({theme})=>theme.screenSize.md ? `none` : 1};
`

const LOCUS_OF_CONTROL = [
  "เด็กบางคนนั้นเกิดมาโชคดี ใช่หรือไม่",
  "นักเรียนถูกกล่าวหาในสิ่งที่ไม่ใช่ความผิดของนักเรียนเองอยู่บ่อยๆหรือไม่",
  "นักเรียนรู้สึกอยู่บ่อยๆว่าพยายามไปก็เท่านั้นเนื่องจากอะไรๆก็แย่ไปหมดอยู่ดีหรือไม่",
  "นักเรียนรู้สึกว่า บิดามารดารับฟังความเห็นของลูกเกือบทุกครั้ง ใช่หรือไม่ ",
  "เวลานักเรียนถูกลงโทษ ส่วนใหญ่แล้วช่างเป็นเรื่องที่ไม่มีเหตุผลเสียเลย ใช่หรือไม่",
  "โดยส่วนมากแล้ว นักเรียนคิดว่า เป็นการยากที่จะเปลี่ยนความคิดเห็นของเพื่อนใช่หรือไม่",
  "นักเรียนคิดว่ามันเป็นเรื่องที่เกือบจะเป็นไปไม่ได้ที่จะเปลี่ยนความคิดเห็นของคุณพ่อคุณแม่ไม่ว่าจะในเรื่องใดก็ตามหรือไม่",
  "นักเรียนรู้สึกว่าเวลาที่นักเรียนทำผิด มันแทบจะไม่มีอะไรที่นักเรียนจะไปแก้ไขได้เลยใช่หรือไม่",
  "นักเรียนเชื่อว่าเด็กส่วนมากนั้นมีพรสวรรค์ทางกีฬาตั้งแต่เกิดหรือไม่",
  "นักเรียนรู้สึกว่าวิธีที่ดีที่สุดวิธีหนึ่งในการจัดการกับปัญหา คือการไม่คิดถึงปัญหานั้น ใช่หรือไม่",
  "นักเรียนรู้สึกว่า ถ้าเด็กที่มีอายุเท่ากันมาทำร้ายนักเรียน นักเรียนแทบจะทำอะไรที่จะหยุดเด็กคนนั้นได้เลย ใช่หรือไม่",
  "นักเรียนเคยรู้สึกว่าหรือไม่ว่า เวลามีคนทำไม่ดีกับนักเรียน การกระทำนั้น เป็นการกระทำที่ไม่มีเหตุผลซะเป็นส่วนใหญ่ ",
  "ส่วนมากแล้ว นักเรียนคิดว่าการกระทำวันนี้จะเปลี่ยนเหตุการณ์พรุ่งนี้ได้ ใช่หรือไม่",
  "นักเรียนเชื่อว่าถ้าสิ่งร้ายๆจะต้องเกิด ไม่ว่านักเรียนจะทำอย่างไร สิ่งร้ายๆนั้นก็จะเกิดอยู่ดี ใช่หรือไม่",
  "โดยส่วนมากแล้ว นักเรียนรู้สึกว่า เวลาที่อยู่ที่บ้าน ความพยายาม ที่จะได้ในมาซึ่งสิ่งที่อยากได้นั้นไม่ค่อยเป็นผล ใช่หรือไม่",
  "นักเรียนรู้สึกว่า ถ้าเด็กที่มีอายุเท่ากันต้องการจะเป็นศัตรูกับนักเรียน นักเรียนแทบจะทำอะไรที่จะเปลี่ยนใจเด็กคนนั้นได้เลย ใช่หรือไม่",
  "นักเรียนรู้สึกว่า นักเรียนมีส่วนในการตัดสินใจในเรื่องอาหารที่ตัวเองจะได้ทานที่บ้าน ใช่หรือไม่",
  "นักเรียนรู้สึกว่าถ้ามีคนไม่ชอบนักเรียน มันก็เป็นเรื่องที่นักเรียนแทบจะแก้ไขไม่ได้ ใช่หรือไม่",
  "ส่วนมากแล้ว นักเรียนรู้สึกว่าพยายามเรื่องเรียนไปก็เท่านั้น เพราะ เด็กส่วนมากนั้นเก่งกว่านักเรียนอยู่ดี ใช่หรือไม่",
  "นักเรียนเป็นคนที่เชื่อว่าการวางแผนล่วงหน้า จะให้ผลลัพธ์ที่ดีกว่า ใช่หรือไม่",
  "ส่วนใหญ่แล้ว นักเรียนรู้สึกว่าตัวเองไม่มีปากมีเสียง กับการตัดสินใจต่างๆของครอบครัว ใช่หรือไม่",
]

function LocusOfControl() {
  const theme = useTheme()
  const { api, loading } = useAPI()
  const { subject, fetchSubject } = useContext(AuthContext)
  const { currentChapterIndex, setCurrentChapterIndex } = useContext(ExamContext)

  const [responses, setResponses] = useState([])
  const [isValid, setIsValid] = useState(false)

  const handleResponse = (index, value) => {
    if (!value) return
    const newResponses = [...responses]
    newResponses[index] = value
    setResponses(newResponses)
  }

  useEffect(() => {
    if (Object.keys(responses).filter(key => typeof responses[key] !== 'undefined').length >= LOCUS_OF_CONTROL.length) {
      setIsValid(true)
    } else {
      setIsValid(false)
    }
  }, [responses])

  const handleSubmit = async () => {
    const locus = parseInt(responses.join(""), 2);
    // _FYI: Reverse bin
    // const locusBin = (locus >>> 0).toString(2)
    // const locusString = Array(LOCUS_OF_CONTROL.length-locusBin.length).fill(0).join("")+locusBin

    try {
      await api.patch(`/subjects/${subject?.id}`, {
        locus,
      })
      fetchSubject(subject?.id)
      setCurrentChapterIndex(currentChapterIndex+1)
    } catch (error) {
      console.warn(error)
    }
  }

  return (
    <>
    <LocusOfControlWrapper>
      <QuestionHeader>
        <QuestionHeaderContainer>
          <QuestionTitle>แบบสอบถาม ไม่มีคำตอบใดถูกหรือผิด เป็นเพียงความคิดเห็น</QuestionTitle>
          {/* <QuestionLabel style={{color: Colors.green500}}>ใช่</QuestionLabel>
          <QuestionLabel style={{color: Colors.red500}}>ไม่ใช่</QuestionLabel> */}
        </QuestionHeaderContainer>
      </QuestionHeader>
      <ScrollViewContainer contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}>
        <QuestionContainer>
          { LOCUS_OF_CONTROL.map((item, index) => {
            return (
              <CheckListItem title={item} key={index} index={index} handleResponse={handleResponse} />
            )
          }) }
          { theme.screenSize.md === true && (<QuestionAction>
            <BrandButton 
              title={`ส่งคำตอบ`}
              disabled={!isValid || loading}
              rightElement={ () => <MaterialIcons name="chevron-right" size={18} color={Colors.slate600} /> } 
              style={{marginLeft: 5}} 
              onPress={handleSubmit} 
              loading={loading}
            />
          </QuestionAction>)}
        </QuestionContainer>
      </ScrollViewContainer>
    </LocusOfControlWrapper>
    { theme.screenSize.md === false && (<QuestionAction>
      <WhiteButton 
        title={`ส่งคำตอบ`}
        disabled={!isValid || loading}
        rightElement={ () => <MaterialIcons name="chevron-right" size={18} color={Colors.slate600} /> } 
        style={{marginLeft: 5}} 
        onPress={handleSubmit} 
        loading={loading}
      />
    </QuestionAction>)}
    </>
  );
}

export default LocusOfControl;