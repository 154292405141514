import React, { useState, useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { useForm, Controller } from "react-hook-form";
import { useNavigation } from '@react-navigation/native';

import useAPI from '../../hooks/useAPI';
import { AuthContext } from '../../contexts/AuthContext'

import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import WhiteButton from '../Commons/WhiteButton';
import BrandButton from '../Commons/BrandButton';
import SelectList from '../Form/SelectList';

const BOTTOM_BAR_HEIGHT = 80

const SurveyWrapper = styled.View`
  flex: 1;
  background: ${Colors.slate100};
`

const ScrollViewContainer = styled.ScrollView`
  flex: ${({theme})=>theme.screenSize.md ? `none` : 1};
`

const QuestionContainer = styled.View`
  background: #fff;
  width: ${({theme})=>theme.screenSize.md ? `100%` : `600px`};
  padding: ${({theme})=>theme.screenSize.md ? `80px 0 100px 0` : `40px 20px 200px 20px`};
`
const QuestionAction = styled.View`
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  height: ${({theme})=>theme.screenSize.md ? `120px` : `${BOTTOM_BAR_HEIGHT}px`};
  z-index: 20;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`

const QuestionTitle = styled.Text`
  flex-grow: 1;
  font-family: ${Fonts.brandBold};
  color: ${Colors.brand2};
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
`
const FormGroup = styled.View`
  flex-direction: row;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.slate300};
`
const LabelContainer = styled.View`
  flex: 2;
  padding: 15px;
  justify-content: center;
`
const InputContainer = styled.View`
  flex: 3;
  padding: 15px;
  justify-content: center;
`
const Lable = styled.Text`
  text-align: right;
  font-family: ${Fonts.brandBold};
  color: ${Colors.slate600};
`

const InputField = styled.TextInput`
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  margin-top: 5px;
`
const ErrorText = styled.Text`
  color: #f00;
  font-family: ${Fonts.brand};
  margin-top: 5px;
`

const TYPE_CAN_CUSTOM_ANSWER = ['guardian_occupation', 'dream_job']

function Survey() {
  const navigation = useNavigation()
  const theme = useTheme()

  const { api } = useAPI()
  const { subject, fetchSubject } = useContext(AuthContext)

  const [surveyKeys, setSurveyKeys] = useState(null)
  const [isSubmiting, setIsSubmiting] = useState(false)
  const [enableCustomValues, setEnableCustomValues] = useState([])

  useEffect(() => {
    (async ()=>{
      try {
        const res = await api.get(`/settings/keys/survey`)
        if (res?.data?.value) {
          setSurveyKeys(res.data.value)
        }
      } catch (error) {
        console.warn(error)
      }
    })()
  },[])

  const { control, handleSubmit, clearErrors, formState: { errors } } = useForm({
    defaultValues: {}
  });

  const onSubmit = async (data) => {
    setIsSubmiting(true)
    try {
      const saveData = {}
      surveyKeys.forEach((item, index) => {
        if (enableCustomValues.includes(item?.type)) {
          saveData[`survey${index+1}_custom`] = data[`${item?.type}_custom`] ? data[`${item?.type}_custom`] : ""
        } else {
          saveData[`survey${index+1}_id`] = data[item?.type]
        }
      })
      const resSubject = await api.patch(`/subjects/${subject.id}`, {
        ...saveData,
      })
      if (resSubject?.data) {
        await fetchSubject(subject.id)
      }
      navigation.navigate('Result')
    } catch (error) {
      console.warn(error)
    }
    setIsSubmiting(false)
  }

  return (
    <>
    <SurveyWrapper>
      <ScrollViewContainer contentContainerStyle={{ flexGrow: 1, alignItems: "center" }}>
        <QuestionContainer>
          <QuestionTitle>กรอกข้อมูลเพิ่มเติม</QuestionTitle>
          
          { surveyKeys && (
            surveyKeys.map(item => {
              let label = item?.text
              if (item.type == 'sliblings') {
                label = "จำนวนพี่น้อง\n(รวมผู้สอบ)"
              }
              if (item.type == 'family_members') {
                label = "จำนวนสมาชิกในครอบครัว\n(รวมผู้สอบ)"
              }

              return (
                <FormGroup key={item.type}>
                  <LabelContainer>
                    <Lable>{label}</Lable>
                  </LabelContainer>
                  <InputContainer>
                    <Controller
                      control={control}
                      rules={{
                        required: true,
                      }}
                      render={({ field: { onChange, onBlur, value } }) => (
                        <>
                          <SelectList 
                            name={item?.type}
                            type={item?.type}
                            placeholder={item?.text}
                            noOptionsMessage="ไม่พบข้อมูล"
                            value={value}
                            searchable={true}
                            appendItems={ TYPE_CAN_CUSTOM_ANSWER.includes(item?.type) ? [{
                              label: "อื่นๆ",
                              value: "custom",
                            }] : [] }
                            onChange={(value, name, item) => {
                              if ( TYPE_CAN_CUSTOM_ANSWER.includes(name) ) {
                                clearErrors(`${name}_custom`)
                                clearErrors(`${name}`)
                              }
                              if (value == "custom" ) {
                                setEnableCustomValues([
                                  ...enableCustomValues,
                                  name,
                                ])
                              } else {
                                const _enableCustomValues = enableCustomValues.filter( item => item !== name )
                                console.log(_enableCustomValues)
                                setEnableCustomValues(_enableCustomValues)
                              }
                              onChange(value)
                            }}
                            onBlur={onBlur}
                            disabled={isSubmiting}
                          />
                        </>
                      )}
                      name={item?.type}
                    />
                    { enableCustomValues.includes(item.type) && (
                      <Controller
                        control={control}
                        rules={{
                          required: true,
                          maxLength: {
                            value: 120,
                            message: "ข้อความยาวเกินไป กรุณากรอกใหม่อีกครั้ง"
                          }
                        }}
                        render={({ field: { onChange, onBlur, value } }) => (
                          <>
                            <InputField 
                              placeholder={item?.text} 
                              value={value}
                              onBlur={onBlur}
                              onChangeText={onChange}
                              disabled={isSubmiting}
                              required={true}
                            />
                          </>
                        )}
                        name={`${item?.type}_custom`}
                      />
                      
                    ) }
                    { (errors[item?.type] || errors[`${item?.type}_custom`]) && 
                      <ErrorText>
                        { errors[`${item?.type}_custom`]?.message 
                          ? errors[`${item?.type}_custom`]?.message 
                          : 'กรุณากรอกข้อมูล' 
                        }
                      </ErrorText>
                    }
                  </InputContainer>
                </FormGroup>
              )
            })
          ) }

          { theme.screenSize.md === true && (<QuestionAction>
            <BrandButton 
              title={`ส่งคำตอบ`} 
              loading={isSubmiting}
              rightElement={ () => <MaterialIcons name="chevron-right" size={18} color={Colors.slate600} /> }
              style={{marginLeft: 5}}
              onPress={handleSubmit(onSubmit)} 
            />
          </QuestionAction>)}
        </QuestionContainer>
      </ScrollViewContainer>
    </SurveyWrapper>
    { theme.screenSize.md === false && (<QuestionAction>
      <WhiteButton 
        title={`ส่งคำตอบ`} 
        loading={isSubmiting}
        rightElement={ () => <MaterialIcons name="chevron-right" size={18} color={Colors.slate600} /> }
        style={{marginLeft: 5}}
        onPress={handleSubmit(onSubmit)} 
      />
    </QuestionAction>)}
    </>
  );
}

export default Survey;