import { useContext, useState } from 'react';
import API from '../libs/API'
import APILocal from '../libs/APILocal'
import { ErrorContext } from '../contexts/ErrorContext';
import DB from '../libs/DB';
import { OFFLINE_MODE } from '../utils/Constant'

function useAPI(defaultData, forceOnline = false) {
  const { hideError, showError } = useContext(ErrorContext)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(defaultData)
  const [error, setError] = useState(null)
  
  const request = async (method, path, params, headers) => {
    hideError()
    setError(null)
    setLoading(true)
    try {
      const res = [API.post, API.put, API.patch]
        .includes(method)
          ? await method(path, params, { headers }) // POST, PUT, PATCH
          : await method(path, { params, headers }) // GET, HEAD, OPTIONS, DELETE, ...

      setData(res?.data)
      setLoading(false)
      return res
    } catch (error) {
      showError(`${error?.response?.data?.message ? error.response.data.message : 'Error: Somthing went wrong.' }`)
      setError(error)
      setData(defaultData)
      setLoading(false)
      if (error?.response?.status === 401 || error?.forceLogout == true ) {
        console.error('Will force logout')
      }
      
      return Promise.reject(error)
    }
  }

  // const api = {
  //   get: (path, params={}, headers={}) => request(API.get, path, params, headers),
  //   post: (path, params={}, headers={}) => request(API.post, path, params, headers),
  //   put: (path, params={}, headers={}) => request(API.put, path, params, headers),
  //   patch: (path, params={}, headers={}) => request(API.patch, path, params, headers),
  //   delete: (path, params={}, headers={}) => request(API.delete, path, params, headers),
  // }

  // // START Local API
  // const apiLocal = {
  //   get: (path, params={}, headers={}) => APILocal.request('get', path, params, headers),
  //   post: (path, params={}, headers={}) => APILocal.request('post', path, params, headers),
  //   put: (path, params={}, headers={}) => APILocal.request('put', path, params, headers),
  //   patch: (path, params={}, headers={}) => APILocal.request('patch', path, params, headers),
  //   delete: (path, params={}, headers={}) => APILocal.request('delete', path, params, headers),
  // }

  const api = {
    get: (path, params={}, headers={}) => handleAPIRequest('get', path, params, headers),
    post: (path, params={}, headers={}) => handleAPIRequest('post', path, params, headers),
    put: (path, params={}, headers={}) => handleAPIRequest('put', path, params, headers),
    patch: (path, params={}, headers={}) => handleAPIRequest('patch', path, params, headers),
    delete: (path, params={}, headers={}) => handleAPIRequest('delete', path, params, headers),
  }

  const handleAPIRequest = async (method, path, params, headers) => {
    // Check is offline or online mode
    const isOfflineMode = await DB.get(OFFLINE_MODE)
    if (isOfflineMode === true && forceOnline !== true) {
      return APILocal.request(method, path, params, headers)
    }
    return request(API[method], path, params, headers)
  }

  return {
    api,
    data,
    loading,
    error,
  }
}

export default useAPI