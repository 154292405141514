import React, { useState, useEffect } from "react";
import useAPI from "../hooks/useAPI";
import AsyncStorage from "@react-native-async-storage/async-storage";

const AuthContext = React.createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isLogin: false,
    user: null,
  });
  const [codeState, setCodeState] = useState(null)
  const [client, setClients] = useState(null)
  const [subjects, setSubjects] = useState([])
  const [subjectsLoading, setSubjectsLoading] = useState(false)
  const [subject, setSubject] = useState(null)
  const { api } = useAPI();

  useEffect(() => {
    (async () => {
      try {
        const accessToken = await AsyncStorage.getItem("access_token");
        if (accessToken) {
          const user = await api.get("/users/me");
          setAuthState({
            isLogin: true,
            user: user?.data,
          });
        }
      } catch (error) {
        console.warn(error)
      }
    })()
  },[])

  const handleLogout = async () => {
    console.log("LOGOUT");
    try {
      await AsyncStorage.removeItem("access_token");
      await AsyncStorage.removeItem("refresh_token");
    } catch (error) {
      console.warn(error);
    }
    setAuthState({
      isLogin: false,
    });
    setCodeState(null)
    setClients(null)
    setSubjects([])
    setSubject(null)
  };

  const handlerLogin = async (credential) => {
    console.log("LOGIN");
    const formData = new FormData();
    formData.append("username", credential?.username);
    formData.append("password", credential?.password);
    try {
      const res = await api.post("/login", formData, {
        "Content-Type": "multipart/form-data",
      });
      const user = await api.get("/users/me");
      setAuthState({
        isLogin: true,
        user: user.data,
      });

      return res;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  const handlerLoginWithCode = async (code) => {
    try {
      const res = await api.post(`/login/code?code=${code}`)
      const user = await api.get("/users/me");
      const resCode = await api.get(`/codes`, {
        code,
      });

      const _codeState = resCode?.data?.data ? resCode.data.data[0] : null
      if (_codeState) {
        setCodeState(_codeState)
        setClients(_codeState?.client)
        setSubjectsLoading(true)
        await fetchAllSubjects(_codeState?.client?.id, 1, [])
        setSubjectsLoading(false)
      }
      setAuthState({
        isLogin: true,
        user: user.data,
      });

      return res;
    } catch (error) {
      console.log(error);
      return Promise.reject(error);
    }
  };

  const fetchAllSubjects = async (client_id, page, prevData) => {
    const pageSize = 100
    const res = await api.get('/subjects', {
      sort: "first_name",
      client_id,
      limit: pageSize,
      skip: (page-1) * pageSize,
    })
    if (res?.data) {
      const allData = [
        ...prevData,
        ...res?.data?.data
      ]
      if (res?.data?.size >= pageSize) {
        return fetchAllSubjects(client_id, page+1, allData)
      } else {
        setSubjects(allData)
        return Promise.resolve()
      }
    }
  }

  const selectSubject = async (subjectId = null) => {
    if (subjectId == null) {
      setSubject(null)
      return
    }
    
    try {
      const resSubject = await api.get(`/subjects/${subjectId}`)
      if (resSubject?.data) {
        setSubject(resSubject?.data) 
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const fetchSubject = async (subjectId) => {
    try {
      const resSubject = await api.get(`/subjects/${subjectId}`)
      if (resSubject?.data) {
        setSubject(resSubject?.data)
      }
    } catch (error) {
      console.warn(error)
    }
  }

  const fetchSubjects = async (clientId) => {
    fetchAllSubjects(clientId, 1, [])
  }

  const fetchUser = async () => {
    try {
      const user = await api.get("/users/me");
      setAuthState({
        isLogin: true,
        user: user?.data,
      });
    } catch (error) {
      handleLogout();
    }
  };

  const hasPermission = (scope) => {
    if (authState?.user?.scopes) {
      return authState?.user?.scopes?.includes(scope);
    }
    return false;
  };

  return (
    <Provider
      value={{
        authState,
        isLogin: authState.isLogin,
        user: authState.user,
        logout: handleLogout,
        login: handlerLogin,
        loginWithCode: handlerLoginWithCode,
        fetchUser,
        hasPermission,
        client,
        codeState,
        subjects,
        selectSubject,
        fetchSubject,
        fetchSubjects,
        subject,
        subjectsLoading,
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
