import React from 'react';
import styled from 'styled-components/native';
import Colors from '../themes/Colors';
import Fonts from '../themes/Fonts';

const CHAPTERS = [
  {
    label: "ตอนที่ 1"
  },
  {
    label: "ตอนที่ 2"
  },
  {
    label: "ตอนที่ 3"
  },
  {
    label: "ตอนที่ 4"
  }
]

const ChapterWrapper = styled.View`
  justify-content: flex-start;
  flex-direction: row;
`
const ChapterItem = styled.View`
  flex-direction: column;
  align-items: center;
  width: 60px;
  opacity: ${({active}) => active ? 1 : 0.4};
`
const Title = styled.Text`
  color: ${Colors.white};
  font-family: ${Fonts.brandBold};
  font-size: 12px;
  display: ${({theme})=> theme.screenSize.md ? 'none' : 'flex'};
`
const Dot = styled.View`
  background: ${Colors.white};
  border-radius: 9px;
  width: 18px;
  height: 18px;
  margin-bottom: 5px;
`
const Line = styled.View`
  position: absolute;
  background: ${Colors.white};
  height: 5px;
  width: 42px;
  top: 7px;
  right: 39px;
`

function ChapterProgress({activeChapter}) {
  return (
    <ChapterWrapper>
      {
        CHAPTERS.map((chapter, index) => {
          return (<ChapterItem key={index} active={activeChapter && activeChapter >= index+1}>
            { index > 0 && <Line /> }
            <Dot />
            <Title>{chapter.label}</Title>
          </ChapterItem>)
        })
      }
    </ChapterWrapper>
  );
}

export default ChapterProgress;