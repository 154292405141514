import React, {useEffect, useState, useContext} from 'react';
import { Alert, Modal, StyleSheet, Text, Pressable, View } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import styled from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons'; 
import CheckBox from "expo-checkbox";
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import BrandButton from '../Commons/BrandButton';
import GhostButton from '../Commons/GhostButton';
import SelectSearchable from '../Commons/SelectSearchable';

import { AuthContext } from '../../contexts/AuthContext'

function AuthPanel() {
  const navigation = useNavigation()
  const route = useRoute()

  const { loginWithCode, logout, client, codeState, subjects, subjectsLoading, selectSubject } = useContext(AuthContext)

  const [code, setCode] = useState("")
  const [student, setStudent] = useState(null)
  const [isCodeFormSubmiting, setIsCodeFormSubmiting] = useState(false)
  const [isCodeFormValid, setIsCodeFormValid] = useState(false)
  const [isCodeInvalid, setIsCodeInvalid] = useState(false)
  const [isStartExamValid, setIsStartExamValid] = useState(false)
  const [isStartExamSubmiting, setIsStartExamSubmiting] = useState(false)
  const [showTermModal, setShowTermModal] = useState(false)
  const [isAcceptTerm, setIsAcceptTerm] = useState(false)

  useEffect(() => {
    if (route.name == "Home" && route.params?.reset === true) {
      setStudent("")
    }
  }, [route.name, route.params])
  
  useEffect(() => {
    if (code && code.length >= 6) {
      setIsCodeFormValid(true)
      return
    }
    setIsCodeFormValid(false)
  }, [code])

  useEffect(() => {
    if (code && code.length >= 6 && student) {
      setIsStartExamValid(true)
      return
    }
    setIsStartExamValid(false)
  }, [code, student])

  const handleOnChangeCode = (code) => {
    setIsCodeInvalid(false)
    setCode(code)
  }
  const onSubmit = async () => {
    setIsCodeInvalid(false)
    // Admin login
    if (code === '......') {
      navigation.navigate("Admin")
      return
    }
    

    setIsCodeFormSubmiting(true)
    try {
      setIsCodeFormSubmiting(true)
      const loginRes = await loginWithCode(code)
      setIsCodeFormSubmiting(false)
      if (!loginRes || !loginRes.data) {
        setIsCodeInvalid(true)
      }
    } catch (error) {
      console.warn(error)
      if (error?.response?.data?.message == "Invalid code") {
        setIsCodeInvalid(true)
      }
    }
    setIsCodeFormSubmiting(false)
  }

  const handleResetCode = () => {
    logout()
    setCode("")
    setStudent(null)
  }

  const handleOnChangeStudent = (student) => {
    setStudent(student)
  }

  const handleStartExam = async (e) => {
    e.preventDefault()
    setIsStartExamSubmiting(true)
    await selectSubject(student)
    setIsStartExamSubmiting(false)
    navigation.navigate("ExamCover", {
      handleBack: () => {
        setStudent(null)
      }
    })
  }

  const handleAddAccount = () => {
    selectSubject(null)
    navigation.navigate("AddEditAccount")
  }
  if (codeState?.id) {
    return (<AuthWrapper>
      <BackButton title="ย้อนกลับ" onPress={handleResetCode} leftElement={() => <MaterialIcons name="chevron-left" size={24} color={Colors.slate600} style={{marginRight: 5}} />} />
      <Label>รหัสคุมสอบ</Label>
      <Code>{code}</Code>
      <Label>โรงเรียน</Label>
      <SchoolLabel>{ client ? client?.name : '-' }</SchoolLabel>
      <Label>ชื่อ - นามสกุล</Label>
      { subjectsLoading ? (
        <Loading size="small" />
      ) : (
        <SelectStudent 
          onChange={handleOnChangeStudent}
          placeholder={`กรุณาเลือก ชื่อ - นามสกุล`} 
          emptyMessage="ไม่พบรายชื่อ"
          value={student}
          items={subjects.map(item => {
            return {
              label: `${item.first_name ? item.first_name : '-'} ${item.last_name ? item.last_name : '' }`,
              value: item.id,
            }
          })}
        />
      ) }
      
      <StartExamButton
        title="ตกลง"
        onPress={handleStartExam}
        disabled={!isStartExamValid || isStartExamSubmiting}
        loading={isStartExamSubmiting}
      />
      <TextButton onPress={handleAddAccount}>
        <TextButtonLabel>ไม่พบรายชื่อ?</TextButtonLabel>
      </TextButton>
    </AuthWrapper>)
  }

  return (
    <CodeWrapper>
      <InputCodeLabel>กรอกรหัสคุมสอบเพื่อเริ่มทำข้อสอบ</InputCodeLabel>
      <InputCode
        onChangeText={code => handleOnChangeCode(code)}
        placeholder="******"
        keyboardType="numeric"
        maxLength={6}
        value={code}
      />
      { isCodeInvalid && (
        <CodeInvalid>รหัสคุมสอบไม่ถูกต้อง</CodeInvalid>
      ) }

      <AcceptTermForm>
        <CheckBox 
          style={{zoom: 1.5}}
          color={Colors.brand2}
          value={isAcceptTerm}
          onValueChange={(isCheck)=>{
            setIsAcceptTerm(isCheck)
          }} 
        />
        <AcceptTermText onPress={()=>{ setShowTermModal(true) }}>ยอมรับข้อตกลงและเงื่อนไขการใช้งาน</AcceptTermText>
      </AcceptTermForm>
      <SubmitCodeButton
        title="ตกลง"
        onPress={onSubmit}
        disabled={!isCodeFormValid || isCodeFormSubmiting || !isAcceptTerm}
        loading={isCodeFormSubmiting}
      />
      
      <Modal
        animationType="slide"
        transparent={true}
        visible={showTermModal}
        onRequestClose={() => {
          setShowTermModal(!showTermModal);
        }}>
        <ModalOverlayView >
          <ModalView style={{elevation: 20}}>
            <ModalHeader>
              <ModalCloseBtn
                onPress={() => setShowTermModal(false)}>
                  <MaterialIcons name="close" size={40} color={Colors.slate600} />
              </ModalCloseBtn>
            </ModalHeader>
            <ScrollViewContainer>
              <ModalTitle>หนังสือขอความยินยอมในการเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลส่วนบุคคล</ModalTitle>
              <ModalText>
                ข้าพเจ้ายินยอมให้กองทุนเสมอภาคทางการศึกษา (กสศ.) ดำเนินการจัดเก็บ รวบรวม เก็บรักษา ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล เพื่อใช้ในการบริหารจัดการด้านการศึกษา และเป็นไปตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ.2562 รวมถึงกฎ ระเบียบ ประกาศ แนวทาง การตีความ ความเห็นทางกฎหมาย และการแก้ไขเพิ่มเติมที่อาจออกโดยหน่วยงานที่มีอำนาจเป็นครั้งคราวภายใต้พระราชบัญญัตินี้ (“พระราชบัญญัติฯ”)  โดยมีข้อกำหนดและเงื่อนไข ดังต่อไปนี้ :
              </ModalText>
              <ModalText>
                <Text style={{fontWeight: "bold"}}>1. ข้อมูลส่วนบุคคล</Text> คือ ข้อมูลของผู้ทำแบบทดสอบสำหรับการลงทะเบียนทดสอบในโครงการพัฒนา Computerized Adaptive Test เพื่อการสำรวจสมรรถนะด้านคณิตศาสตร์ของผู้เรียนระดับชั้นมัธยมศึกษาตอนต้น ได้แก่ ชื่อ-นามสกุล, ชื่อโรงเรียน, ระดับชั้นเรียน, เลขที่, เพศ, หมายเลขประจำตัวประชาชน (ถ้ามี) และข้อมูลพื้นฐานเพิ่มเติม ได้แก่ อายุ, จำนวนพี่น้อง , จำนวนสมาชิกในครอบครัว , อาชีพของผู้ปกครอง , รายได้ครัวเรือน , ระดับการศึกษาสูงสุดที่คาดหวัง , อาชีพในฝัน เป็นต้น โดย กองทุนเพื่อความเสมอภาคทางการศึกษา (กสศ.) จะดำเนินการด้วยมาตรการที่เข้มงวดในการรักษาความลับ และดูแลข้อมูลส่วนบุคคลของท่านให้ปลอดภัย
              </ModalText>
              <ModalText>
                <Text style={{fontWeight: "bold"}}>2. วัตถุประสงค์การเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคล</Text> เพื่อจัดทำเป็นประวัติ หรือทะเบียนข้อมูลของผู้ทำแบบทดสอบ การตรวจสอบ ยืนยันตัวตน และการประเมินผลข้อมูล อันได้แก่ การปฏิบัติการใดๆ กับข้อมูลไม่ว่าจะเป็นการรวบรวม การบันทึก การจัดเรียง การเก็บรักษา การแก้ไขเพิ่มเติม การใช้ การนำกลับมาใช้ การเปิดเผย การทำให้เข้าถึง และการรายงานเชิงสถิติ สำหรับนำไปใช้ในการสนับสนุนด้านการศึกษาต่อไป
              </ModalText>
              <ModalText>
                <Text style={{fontWeight: "bold"}}>3. ระยะเวลาการจัดเก็บข้อมูลส่วนบุคคล</Text> จะถูกจัดเก็บ รวบรวม ใช้และเปิดเผยข้อมูลส่วนบุคคล อย่างน้อย 3 ปี หรือตามระยะเวลาที่กฎหมายกำหนด
              </ModalText>
              <ModalText>
                <Text style={{fontWeight: "bold"}}>4. สิทธิของเจ้าของข้อมูล</Text> ท่านมีสิทธิในการแก้ไขข้อมูลส่วนบุคคล, ลบหรือทำลาย, เข้าถึงข้อมูลส่วนบุคคล, โอนข้อมูลส่วนบุคคล, คัดค้านการเก็บรวบรวม ใช้ เปิดเผย หรือระงับการใช้ข้อมูลส่วนบุคคลของท่าน โดยแจ้งความประสงค์มายังกองทุนเพื่อความเสมอภาคทางการศึกษา (กสศ.)
              </ModalText>
            </ScrollViewContainer>
            <ModalAcceptBtn
              onPress={() => {
                setIsAcceptTerm(true)
                setShowTermModal(false)
              }}>
                <ModalAcceptText>ยอมรับข้อตกลงและเงื่อนไขการใช้งาน</ModalAcceptText>
            </ModalAcceptBtn>
          </ModalView>
        </ModalOverlayView>
      </Modal>

    </CodeWrapper>
  );
}

export default AuthPanel;

const InputCodeLabel = styled.Text`
  font-family: ${Fonts.brand};
  margin-bottom: 10px;
  text-align: center;
`
const InputCode = styled.TextInput`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid ${Colors.slate300};
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin-bottom: 10px;
`
const CodeInvalid = styled.Text`
  margin-bottom: 10px;
  text-align: center;
  color: #f00;
`

const CodeWrapper = styled.View`
  width: 100%;
  padding: 15px;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
`

const AuthWrapper = styled.View`
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 15px;
`

const BackButton = styled(GhostButton)`
  position: absolute;
  left: 20px;
  top: 20px;
`

const Code = styled.Text`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid ${Colors.slate300};
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 10px;
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin-bottom: 20px;
  margin-top: -15px;
`
const SchoolLabel = styled.Text`
  padding: 15px 20px;
  border-radius: 4px;
  border: 1px solid ${Colors.slate300};
  width: 100%;
  max-width: 300px;
  text-align: center;
  margin-bottom: 15px;
  font-family: ${Fonts.brand};
  margin-top: -15px;
`
const Label = styled.Text`
  font-family: ${Fonts.brandBold};
  margin-bottom: 5px;
  text-align: center;
  background: ${Colors.white};
  padding: 0 10px;
  z-index: 1;
  color: ${Colors.slate600};
`

const SubmitCodeButton = styled(BrandButton)`
  width: 100%;
  max-width: 300px;
  margin-bottom: 15px;
`

const StartExamButton = styled(BrandButton)`
  width: 100%;
  max-width: 300px;
  margin-bottom: 15px;
`

const SelectStudent = styled(SelectSearchable)`
  width: 100%;
  max-width: 300px;
  margin-bottom: 20px;
  margin-top: -15px;
`

const TextButton = styled.TouchableOpacity`

`
const TextButtonLabel = styled.Text`
  font-family: ${Fonts.brandBold};
`

const Loading = styled.ActivityIndicator`
  margin-bottom: 10px;
`

const ModalOverlayView = styled.View`
  flex: 1;
  justify-content: center;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.8); */
`

const ModalView = styled.View`
  margin: 20px;
  background: #fff;
  border-radius: 20px;
  padding: 35px;
  padding-top: 10px;
  align-items: center;
  box-shadow: 0 2px 50px #000;
  max-width: 760px;
`
const ModalHeader = styled.View`
  display: flex;
  width: 100%;
  min-width: 100%;
  justify-content: flex-end;
  align-items: flex-end;
`

const ModalTitle = styled.Text`
  font-family: ${Fonts.brandBold};
  margin-bottom: 15px;
  font-size: 18px;
`
const ModalText = styled.Text`
  margin-bottom: 15px;
`

const ModalCloseBtn = styled.TouchableOpacity`
  padding: 10px;
`
const ModalAcceptBtn = styled.Pressable`
  padding: 10px 20px;
  background: ${Colors.brand2};
  border-radius: 4px;
`
const ModalAcceptText = styled.Text`
  color: #fff;
  font-size: 16px;
  font-family: ${Fonts.brandBold};
`
const ScrollViewContainer = styled.ScrollView`
  padding: 0 0 20px;
  flex: 1;
  height: 100%;
  min-height: 400px;
  max-height: 500px;
  
`

const AcceptTermForm = styled.View`
  margin-bottom: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  max-width: 300px;
`
const AcceptTermText = styled.Text`
  color: ${Colors.brand2};
  flex: 1;
  font-family: ${Fonts.brand};
  padding: 10px;
`