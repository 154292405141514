import React, { useEffect, useState } from 'react';
import styled from 'styled-components/native';
import Colors from '../../themes/Colors';
import { MaterialIcons } from '@expo/vector-icons'; 
import Fonts from '../../themes/Fonts';

const SearchContainer = styled.View`
  background: ${Colors.white};
  position: absolute;
  z-index: 10;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`
const SearchHeader = styled.View`
  flex-direction: row;
  padding: 15px;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.slate300};
  background: ${Colors.slate100};
`
const SearchInputWrapper = styled.View`
  border: 1px solid ${Colors.slate300};
  flex-grow: 1;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 4px;
  margin-right: 60px;
  background: ${Colors.white};
`
const SearchInput = styled.TextInput`
  padding: 10px;
  height: 40px;
  font-family: ${Fonts.brand};
  width: 100%;
  padding-left: 40px;
`
const CloseButton = styled.TouchableOpacity`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 20px;
`
const ListItems = styled.FlatList`
  
`
const Item = styled.TouchableOpacity`
  padding: 15px 20px;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.slate300};
`
const ItemLabel = styled.Text`
  font-family: ${Fonts.brand};
`
const EmptyItem = styled.Text`
  font-family: ${Fonts.brand};
  text-align: center;
  padding: 20px;
  color: ${Colors.slate600};
`

const SelectButton = styled.TouchableOpacity`
  border-radius: 4px;
  border: 1px solid ${Colors.slate300};
  padding: 15px 60px 15px 20px;
  position: relative;
`
const DropdownIcon = styled(MaterialIcons)`
  position: absolute;
  right: 10px;
  top: 13px;
`
const SelectButtonLabel = styled.Text`
  font-family: ${Fonts.brand};
`
const SelectButtonLabelPlaceholder = styled.Text`
  font-family: ${Fonts.brand};
  color: ${Colors.slate500};
`

function SelectSearchable({items, value, onChange, placeholder, emptyMessage, ...props}) {

  const [openSearchView, setOpenSearchView] = useState(false)
  const [selectValue, setSelectValue] = useState(value)
  const [dataSource, setDataSource] = useState(items)

  useEffect(() => {
    setSelectValue(value)
  }, [value])

  const handleOpenSearchView = () => {
    setOpenSearchView(true)
    setDataSource(items)
  }
  const handleCloseSearchView = () => {
    setOpenSearchView(false)
    setDataSource(items)
  }
  const handleSelect = (select) => {
    setSelectValue(select?.value)
    setOpenSearchView(false)
    setDataSource(items)
    if (typeof onChange == "function") {
      onChange(select?.value)
    }
  }

  const handleSearchFilter = (text) => {
    const newDataSource = items.filter(item => {
      const itemData = item.label ? item.label.toUpperCase() : ''.toUpperCase();
      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    })

    setDataSource(newDataSource)
  }

  return (
    <>
      {openSearchView && 
        <SearchContainer >
          <SearchHeader>
            <SearchInputWrapper>
              <MaterialIcons style={{
                position: 'absolute',
                left: 10,
              }} name="search" size={24} color={Colors.slate300} />  
              <SearchInput onChangeText={(text)=>{
                handleSearchFilter(text)
              }} placeholder={`ค้นหา`} />
            </SearchInputWrapper>
            <CloseButton onPress={handleCloseSearchView}>
              <MaterialIcons name="close" size={24} color={Colors.slate600} />
              <SelectButtonLabel> ปิด</SelectButtonLabel>
            </CloseButton>
          </SearchHeader>
          
          <ListItems 
            data={dataSource}
            renderItem={({ item }) => (
              <Item onPress={() => {handleSelect(item)}}>
                <ItemLabel>{item.label}</ItemLabel>
              </Item>
            )}
            keyExtractor={(item) => item?.value}
            enableEmptySections={true}
            ListEmptyComponent={()=>{
              return <EmptyItem>{emptyMessage ? emptyMessage : 'ไม่พบข้อมูล' }</EmptyItem>
            }}
          />
        </SearchContainer> 
      }
      <SelectButton onPress={handleOpenSearchView} {...props}>
        <DropdownIcon name="search" size={24} color={Colors.slate600} />
        { selectValue 
          ? <SelectButtonLabel>{ items?.find( item => item.value == selectValue )?.label}</SelectButtonLabel>
          : <SelectButtonLabelPlaceholder>{placeholder}</SelectButtonLabelPlaceholder>
        }
      </SelectButton>
    </>
  );
}

export default SelectSearchable;