import React, { useState, useEffect, useContext } from 'react';
import { Alert, Platform } from 'react-native'
import styled, { useTheme } from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { ExamContext } from '../contexts/ExamContext'

import Colors from '../themes/Colors';
import listQustions from '../data/listQustions'

import GhostButton from '../components/Commons/GhostButton';
import Exam from '../components/Exam';
import BottomBar from '../components/BottomBar';
import ExamCover from '../components/Exam/ExamCover'
import LocusOfControlCover from '../components/Exam/LocusOfControlCover';
import LocusOfControl from '../components/Exam/LocusOfControl';
import Survey from '../components/Exam/Survey';
import { AuthContext } from '../contexts/AuthContext';

const TopLeftPanel = styled.View`
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 10;
`

const ExamWrapper = styled.View`
  flex: 1;
  flex-direction: row;
`
const ExamScrollViewWrapper = styled.ScrollView`
  height: 100%;
`
const TestModeWrapper = styled.View`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 20px;
  background: #f00;
  z-index: 1000;
  text-align: center;
`
const TestModeText = styled.Text`
  color: #fff;
  font-weight: bold;
`
const ExitButton = styled(GhostButton)`
  background: ${Colors.white};
`

function ExamScreen({ navigation }) {
  const theme = useTheme()
  const { logout } = useContext(AuthContext)
  const { nodeData, exitExam, currentChapterIndex, setCurrentChapterIndex, isTestMode, test, ...examContext } = useContext(ExamContext)

  const [chapters, setChapters] = useState([])
  const [showExamCover, setShowExamCover] = useState(false)
  const [showSurvey, setShowSurvey] = useState(false)

  useEffect(() => {
    if ( Platform.OS == 'web' ) {
      window.onbeforeunload = function() {
        return "ต้องการจะออกจากการทำข้อสอบ?"
      }
      let viewport_meta = document.getElementsByName("viewport")
      if (viewport_meta && viewport_meta[0]) {
        viewport_meta?.[0]?.setAttribute( 'content', 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=10, viewport-fit=cover, user-scalable=yes' )
      }
    }
    return () => {
      window.onbeforeunload = null
    }
  },[])

  useEffect(() => {
    if (!nodeData) return
    const _chapters = [
      ...nodeData.map((_, index) => {
        return {
          id: index+1,
          type: "exam",
          label: `ตอนที่ ${index+1}`,
          nodeColIndex: index,
        }
      }),
      {
        id: nodeData.length + 1,
        type: "locus-of-control",
        label: `ตอนที่ ${nodeData.length + 1}`
      },
    ]
    setChapters(_chapters)
  }, [nodeData])

  useEffect(() => {
    if (!chapters.length) return

    if (currentChapterIndex > chapters.length) {
      navigation.navigate('Result')
      return
    }
    if (currentChapterIndex == chapters.length) {
      setShowSurvey(true)
      return
    }
    
    setShowSurvey(false)
    setShowExamCover(true)
    
  },[currentChapterIndex, chapters])

  const handleExitExam = () => {
    if ( Platform.OS == 'web' ) {
      const confirmExitExam = confirm("ต้องการจะออกจากการทำข้อสอบ?");
      if (confirmExitExam) {
        logout()
        exitExam()
        navigation.navigate('Home', {
          reset: true,
        })
      }
      return
    }
    Alert.alert('ต้องการออกจากการทำข้อสอบหรือไม่?', null, [
      {
        text: 'ไม่ต้องการ',
        onPress: () => null,
        style: 'cancel',
      },
      { 
        text: 'ต้องการ', 
        onPress: () => {
          logout()
          exitExam()
          navigation.reset({
            index: 0,
            routes: [{name: 'Home', params: {
              reset: true,
            }}],
          });
        } 
      },
    ]);
  }

  const handleStartChapter = () => {
    setShowExamCover(false)
  }

  const renderContent = () => {
    if ( showSurvey == true ) {
      return <Survey />
    }

    if ( chapters && chapters[currentChapterIndex] && chapters[currentChapterIndex].type == 'locus-of-control' ) {
      return showExamCover 
        ? <LocusOfControlCover chapter={chapters[currentChapterIndex]} handleStartChapter={handleStartChapter} />
        : <LocusOfControl />
    }

    return showExamCover
      ? <ExamCover chapter={chapters[currentChapterIndex]} handleStartChapter={handleStartChapter} />
      : <Exam />
  }

  const Wrapper = theme.screenSize.md ? ExamScrollViewWrapper : ExamWrapper

  return (
    <Wrapper>
      { isTestMode && <TestModeWrapper>
        <TestModeText>{test?.name} | โหมดทดสอบ จะไม่มีการเก็บคะแนนในระบบ </TestModeText>
      </TestModeWrapper> }
      <TopLeftPanel>
        <ExitButton 
          onPress={handleExitExam} 
          title="ออกจากการทำข้อสอบ"
          leftElement={()=> <MaterialIcons name="exit-to-app" size={24} color={Colors.slate600} style={{marginRight: 5}} />}
        />
      </TopLeftPanel>
      { renderContent() }
      <BottomBar activeChapter={currentChapterIndex+1}/>
    </Wrapper>
  );
}

export default ExamScreen;