import React from 'react';
import { useWindowDimensions } from 'react-native'
import styled, { useTheme } from 'styled-components/native';
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import BrandButton from '../Commons/BrandButton'


const CoverLabel = styled.Text`
  color: ${Colors.brand2};
  font-family: ${Fonts.brandBold};
  font-size: 18px;
  flex-shrink: 1;
  margin-bottom: 10px;
`
const CoverTitle = styled.Text`
  background: ${Colors.brand};
  color: ${Colors.white};
  font-family: ${Fonts.brand2};
  font-size: 44px;
  flex-shrink: 1;
  padding: 0 10px;
`
const CoverAction = styled.View`
  align-items: center;
  margin-top: 20px;
`

function ExamCover({chapter, handleStartChapter}) {
  const theme = useTheme()
  const windowDimensions = useWindowDimensions()
  
  const CoverWrapperDesktop = styled.View`
    background: ${Colors.white};
    align-items: center;
    justify-content: center;
    flex: 1;
  `
  const CoverWrapperMobile = styled.View`
    align-items: center;
    justify-content: center;
    display: flex;
    background: #fff;
    height: ${windowDimensions.height}px;
  `

  const CoverWrapper = theme.screenSize.md ? CoverWrapperMobile : CoverWrapperDesktop

  return (
    <CoverWrapper>
      <CoverLabel>ข้อสอบ</CoverLabel>
      <CoverTitle>{chapter && chapter.label}</CoverTitle>
      <CoverAction>
        <BrandButton title="เริ่มทำข้อสอบ" onPress={handleStartChapter} />
      </CoverAction>
    </CoverWrapper>
  );
}

export default ExamCover;