import React, { useState } from "react";

const ErrorContext = React.createContext();
const { Provider } = ErrorContext;

const ErrorProvider = ({ children }) => {
  const [errorState, setErrorState] = useState({
    showError: false,
    errorMessage: null,
  });

  const showError = (errorMessage) => {
    setErrorState({
      showError: true,
      errorMessage,
    })
  }

  const hideError = () => {
    setErrorState({
      showError: false,
      errorMessage: null,
    })
  }

 return (
   <Provider
     value={{
      errorState,
      setErrorState,
      showError,
      hideError,
    }}
   >
    {children}
   </Provider>
 );
};

export { ErrorContext, ErrorProvider };