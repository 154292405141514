import React from 'react';
import styled from 'styled-components/native';
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';

const FillInWrapper = styled.View`
  padding: 0 20px;
`

const FillInTitle = styled.Text`
  font-size: 14px;
  font-family: ${Fonts.brandBold};
  color: ${Colors.slate500};
  margin-bottom: 15px;
`
const FillInContainer = styled.View`
  padding-bottom: 100px;
`

const InputAnswer = styled.TextInput`
  padding: 10px 20px;
  border-radius: 10px;
  border: 1px solid ${Colors.slate300};
  font-size: 24px;
  font-weight: bold;
  font-family: ${Fonts.brand};
  width: 100%;
  margin-bottom: 10px;
`

function FillIn({ handleOnChangeFill, fillValue }) {
  return (
    <FillInWrapper>
      <FillInTitle>ตอบคำถาม</FillInTitle>
      <FillInContainer>
        <InputAnswer
          onChangeText={value => handleOnChangeFill(value)}
          placeholder="เติมคำตอบ"
          keyboardType="numeric"
          value={fillValue}
        />
      </FillInContainer>
    </FillInWrapper>
  );
}

export default FillIn;
