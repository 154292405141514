import { v4 as uuidv4 } from 'uuid';
import DB from './DB'
import { ATTEMPT_DATA, LIST_DATA, LOGIN_CODE, SUBJECT_DATA, SURVEY_LIST_DATA } from '../utils/Constant'
import { estimateAbilityEAP, information } from '@geekie/irt'
import { normalizeAbility } from '../utils/Helpers'

const AppFunction = {
    get: {},
    post: {},
    put: {},
    patch: {},
    delete: {},
}

AppFunction.post['/login/code'] = async (pathParam, params, headers ) => {
  try {
    const isCodeValid = await DB.getPackageByCode(pathParam.code)
    if (!isCodeValid) {
      return null
    }
    await DB.set(LOGIN_CODE, pathParam.code)
    return {
        access_token: "local_token",
        refresh_token: "local_refresh_token",
        token_type: "bearer"
    }
  } catch (error) {
    Promise.reject({message: 'Invalid code'})
  }
}

AppFunction.get['/users/me'] = async (pathParam, params, headers ) => {
    return {
        "first_name": "local",
        "last_name": "local",
        "image": "",
        "id": "",
        "username": "local",
        "has_password": true,
        "clients": [
          {
            "name": "string",
            "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
            "type": {
              "type": "client_type",
              "name": "string",
              "svalue": "string",
              "ivalue": 0,
              "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
            },
            "location": {
              "type": "client_type",
              "name": "string",
              "svalue": "string",
              "ivalue": 0,
              "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6"
            }
          }
        ],
        "scopes": [
          "string"
        ],
        "created_at": "2022-10-20T04:35:45.063Z",
        "created_by": {
          "first_name": "string",
          "last_name": "string",
          "image": "string",
          "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "username": "string"
        },
        "updated_at": "2022-10-20T04:35:45.063Z",
        "updated_by": {
          "first_name": "string",
          "last_name": "string",
          "image": "string",
          "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "username": "string"
        },
        "deleted": false
      }
}

AppFunction.get['/codes'] = async (pathParam, params, headers ) => {
    const codeRes = await DB.getPackageDataByKey(params.code, 'code')
    return {
        data: [codeRes]
    }
}

AppFunction.get['/subjects'] = async (pathParam, params, headers ) => {
    const code = await DB.get(LOGIN_CODE)
    // Find subjects from code mearge with new subject data
    const subjectsRes = await DB.getPackageDataByKey(code, 'subjects')
    const subjectData = await DB.get(SUBJECT_DATA)
    // const subjects = subjectsRes.map(subject => {
    //     if (subjectData && subjectData[subject.id]) {
    //         return subjectData[subject.id]
    //     }
    //     return subject
    // });
   
    return {
        data: subjectsRes
    }
}

AppFunction.get['/subjects/{id}'] = async (pathParam, params, headers, uuid ) => {
    // Find in SUBJECT_DATA first
    // const subjectData = await DB.get(SUBJECT_DATA)
    // if (subjectData && subjectData[uuid]) {
    //     return subjectData[uuid]
    // }

    const code = await DB.get(LOGIN_CODE)
    const subjectsRes = await DB.getPackageDataByKey(code, 'subjects')
    const subject = subjectsRes.find( item => item.id == uuid )
    return subject
}
AppFunction.post['/subjects'] = async (pathParam, params, headers ) => {
    const subject = await DB.saveSubject(null, params)
    return subject
}
AppFunction.patch['/subjects/{id}'] = async (pathParam, params, headers, uuid ) => {
    const subject = await DB.saveSubject(uuid, params)
    return subject
}

AppFunction.get['/graphs/{id}'] = async (pathParam, params, headers, uuid ) => {
    const code = await DB.get(LOGIN_CODE)
    const graphRes = await DB.getPackageDataByKey(code, 'graph')
    const graph = graphRes.id == uuid ? graphRes : null

    // Find latest_test by check with attempt and responses
    if (pathParam && params.attempt_id) {
      const attemptData = await DB.get(ATTEMPT_DATA)
      // console.log('attemptData', attemptData)
      if (attemptData && attemptData[params.attempt_id]) {
        const attempt = attemptData[params.attempt_id]
        // console.log('attempt', attempt)
        // Find last responses in attempt
        if (attempt && Array.isArray(attempt.responses) ) {
          const latest_responses = attempt.responses[attempt.responses.length -1]
          console.log('latest_responses', latest_responses)
          if (latest_responses && latest_responses.test_id){
            // Find test by test_id
            const testData = await DB.getPackageDataByKey(code, 'tests')
            if (testData && Array.isArray(testData)) {
              graph.latest_test = testData.find(item => item.id == latest_responses.test_id)
            }
          }
        }
      }
    }
    return graph
}

AppFunction.get['/lists'] = async (pathParam, params, headers, uuid ) => {
    const listData = await DB.getListDataByType(params.type)
    return {
        data: listData,
    }
}

AppFunction.get['/attempts'] = async (pathParam, params, headers ) => {
  const { code_id, subject_id } = pathParam
  const attemptRes = await DB.get(ATTEMPT_DATA)
  const attempts = Object.keys(attemptRes).map(key => attemptRes[key])
  if (Array.isArray(attempts)) {
    const attempt = attempts.find(item => {
      if (item?.subject?.id == subject_id && item?.code?.id == code_id) {
        return true
      }
      return false
    })
    return {
      data: [attempt]
    }
  }
  return false
}

AppFunction.post['/attempts'] = async (pathParam, params, headers ) => {
  const { code_id, subject_id } = params
  const attemptRes = await DB.saveAttempt(code_id, subject_id)
  return attemptRes
}

AppFunction.get['/tests/{id}'] = async (pathParam, params, headers, uuid ) => {
  const code = await DB.get(LOGIN_CODE)
  const testsRes = await DB.getPackageDataByKey(code, 'tests')
  if (Array.isArray(testsRes)) {
    const test = testsRes.find(item => item.id == uuid)
    if (test) {
      return test
    }
  }
  return false
}

AppFunction.get['/items'] = async (pathParam, params, headers, uuid ) => {
  const { test_id } = params
  const code = await DB.get(LOGIN_CODE)
  const itemsRes = await DB.getPackageDataByKey(code, 'items')
  if (Array.isArray(itemsRes)) {
    const items = itemsRes.filter(item => item?.test?.id == test_id)
    if (items) {
      return {
        data: items
      }
    }
  }
  return false
}

AppFunction.post['/responses'] = async (pathParam, params, headers, uuid ) => {
  const { attempt_id } = params
  const attemptData = await DB.get(ATTEMPT_DATA)
  if (attemptData[attempt_id]) {
    if (!attemptData[attempt_id].responses){
      attemptData[attempt_id].responses = []
    }
    const response = {
      ...params,
      id: uuidv4()
    }
    attemptData[attempt_id].responses.push(response)
    await DB.set(ATTEMPT_DATA, attemptData)
    return response
  }
  return false
}

const findAbility = async (attempt_id) => {
  const code = await DB.get(LOGIN_CODE)
  const attemptData = await DB.get(ATTEMPT_DATA)

  if (attemptData && attemptData[attempt_id] && attemptData[attempt_id].responses) {
    const responses = attemptData[attempt_id].responses
    console.log('responses', responses)
    let answers = []
    let zeta = []
    const itemsRes = await DB.getPackageDataByKey(code, 'items')
    if (Array.isArray(itemsRes) && Array.isArray(responses)) {
      responses.forEach(response => {
        if ( response && response.answers && Array.isArray(response.answers) ) {
          response.answers.forEach(answer => {
            const item = itemsRes.find(item => item?.id == answer.item_id)
            if (item) {
              console.log('item', item)
              if (item.question_type == "fill_float") {
                answers.push(answer.fvalue == item.question_fvalue ? 1 : 0)
              } else {
                answers.push(answer.ivalue == item.question_ivalue ? 1 : 0)
              }
              zeta.push({
                a: item.a,
                b: item.b,
                c: item.c,
              })
            }
          })
        }
      })

      console.log('answers', answers)
      console.log('zeta', zeta)
      const ability = estimateAbilityEAP(answers, zeta)
      console.log(ability)
      console.log('estimateAbilityEAP', answers, zeta, ability)
      return ability 
    }
  }
}

AppFunction.get['/irt/test_info'] = async (pathParam, params, headers, uuid ) => {
  const code = await DB.get(LOGIN_CODE)
  const { attempt_id, test_id } = params
  const ability = await findAbility(attempt_id)
  console.log(ability)
  
  // Find infomation
  const itemsRes = await DB.getPackageDataByKey(code, 'items')
  const test_items = itemsRes.filter(item => item?.test?.id == test_id)
  if (test_items && Array.isArray(test_items)) {
    const testZeta = test_items.map(test_item => {
      return {
        a: test_item.a,
        b: test_item.b,
        c: test_item.c,
      }
    })
    console.log('before info', testZeta, ability)
    const info = information(testZeta, ability)
    console.log('info', info)
    return info
  }
  return false
}

AppFunction.get['/irt/score'] = async (pathParam, params, headers, uuid ) => {
  const { attempt_id } = params
  const ability = await findAbility(attempt_id)
  const BANDS_POINTS = [-2.5, -1.5, 0.5, 1.5, 2.5]
  let band = 1
  BANDS_POINTS.forEach((point) => {
    if (ability > point) {
      band++
    }
  })
  const roundAbility = ability < -5 ? -5 : ability > 5 ? 5 : ability
  return {
    raw: 0,
    true: 0,
    max: 0,
    band,
    p: normalizeAbility(roundAbility),
  }
}

AppFunction.get['/irt/ability_estimate'] = async (pathParam, params, headers, uuid ) => {
  const { attempt_id } = params
  const ability = await findAbility(attempt_id)
  // set ability between -5 - 5
  let roundAbility = ability < -5 ? -5 : ability > 5 ? 5 : ability
  return {
    value: roundAbility,
    error: 0,
    n: 0
  }
}


AppFunction.get['/settings/keys/survey'] = async (pathParam, params, headers, uuid ) => {
  const surveyListData = await DB.get(SURVEY_LIST_DATA)
  return surveyListData
}


export default AppFunction