import React, { useEffect, useContext } from 'react';
import styled, { useTheme } from 'styled-components/native';
import Colors from '../themes/Colors';
import Fonts from '../themes/Fonts';
import Images from '../themes/Images'

import { ExamContext } from '../contexts/ExamContext'
import { AuthContext } from '../contexts/AuthContext'

import BrandButton from '../components/Commons/BrandButton'

const BAND_CONTENT = {
  1: "ผู้สอบสามารถตอบคำถามที่เกี่ยวข้องกับบริบทที่เข้าใจง่าย มีการให้ข้อมูลที่เกี่ยวข้องในรูปแบบที่เรียบง่ายและคุ้นเคยได้ สามารถปฏิบัติตามคำสั่งได้อย่างชัดเจน มีการแก้ปัญหาขั้นตอนเดียว",
  2: "ผู้สอบสามารถตีความ แปลความ และรู้สถานการณ์ที่ไม่ซับซ้อน สามารถใช้ตัวอ้างอิงไม่เกิน 2 ตัวได้ สามารถสกัดสาระสำคัญจากแหล่งข้อมูลแหล่งเดียว และสามารถใช้สถานการณ์ที่นำเสนอง่ายๆ เพียงชั้นเดียว สามารถใช้วิธีการคิดสูตรคณิตศาสตร์ สามารถคิดวิธีการ หรือข้อตกลงเบื้องต้น",
  3: "ผู้สอบสามารถแก้ปัญหาตามตัวอย่างหรือวิธีการที่บอกไว้ชัดเจน รวมทั้งปัญหาที่ต้องเลือกลำดับขั้นตอน สามารถเลือกและใช้กลยุทธ์ที่ไม่ซับซ้อนสำหรับการแก้ปัญหา สามารถตีความ แปลความ และใช้สถานการณ์ที่มีที่มาจากหลายแหล่งได้",
  4: "ผู้สอบสามารถทำโจทย์คณิตศาสตร์ที่มีรูปแบบชัดเจน แต่อยู่ในสถานการณ์ค่อนข้างซับซ้อนและอาจมีข้อจำกัดเข้ามาเกี่ยวข้อง สามารถใช้ทักษะทางคณิตศาสตร์ที่มีอยู่จำกัด และสามารถใช้เหตุผลได้ แก้ปัญหาจากสถานการณ์ที่ไม่ซับซ้อน",
  5: "ผู้สอบสามารถสร้างตัวแบบของสถานการณ์จริงได้ สามารถเลือก เปรียบเทียบ และประเมินถึงกลยุทธ์การแก้ปัญหาที่เหมาะสม สามารถสร้างกฎเกณฑ์ทางคณิตศาสตร์ และสามารถสื่อสารการแปลความ ตีความ และการใช้เหตุผลของตนให้เป็นที่เข้าใจได้",
  6: "ผู้สอบสามารถสร้างกรอบความคิด สร้างข้อสรุป สร้างตัวแบบของสถานการณ์ที่ซับซ้อนมากๆ ได้ มีความคิดเชิงคณิตศาสตร์ขั้นสูงและการใช้เหตุผลทางคณิตศาสตร์ได้เป็นอย่างดี สามารถใช้ความสัมพันธ์ของตัวแปร สัญลักษณ์ การดำเนินการ และความสัมพันธ์ทางคณิตศาสตร์เพื่อนำมาสร้างวิธีการใหม่ๆ ได้ สามารถใช้คณิตศาสตร์ในการแก้ปัญหาในสถานการณ์จริงที่มีบริบทซับซ้อนได้",
}

function ResultScreen({navigation}) {

  const { fetchScore, attempt, score, exitExam, ability } = useContext(ExamContext)
  const { logout } = useContext(AuthContext)

  const theme = useTheme()

  useEffect(() => {
    (async() => {
      await fetchScore(attempt?.id)
    })()
  }, [])

  const handleRestartExam = () => {
    exitExam()
    logout()
    navigation.navigate("Home", {
      reset: true,
    })
  }

  const getBandContent = (band) => {
    if (BAND_CONTENT[band]) {
      return BAND_CONTENT[band]
    }
    return ""
  }

  return (
    <ResultWrapper >
      <ResultScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <ResultBackground source={Images.resultBg} resizeMode={`cover`} />
        <Logo source={Images.eefXBootcampLogo} resizeMode="contain" />
        <ResultContainer>
          <ResultScoreContainer>
            <ResultScoreLeftCol>
              <RawScoreLabel>คะแนนความเก่งของคุณ</RawScoreLabel>
              <RawScore>
                <RawScoreBold>{(score?.p * 100).toFixed(2) || '...'}</RawScoreBold>/100 คะแนน
              </RawScore>
              <BandScoreLabel>ผู้สอบอยู่ในระดับที่</BandScoreLabel>
              <BandScoreContainer>
                <BandScore>{score?.band}</BandScore>
              </BandScoreContainer>
            </ResultScoreLeftCol>
            <ResultScoreRightCol>
              <ResultSummaryGraphicLine1 />
              <ResultSummaryGraphicLine2 />
              <ResultSummaryContent>
                “{getBandContent(score?.band)}“
              </ResultSummaryContent>
              <ResultSummaryGraphicLine3 />
              <ResultSummaryGraphicLine4 />
            </ResultScoreRightCol>
          </ResultScoreContainer>
          {/* <ResultContentContainer>
            <ResultContent>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ResultContent>
            <ResultContent>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ResultContent>
          </ResultContentContainer> */}
          <ResultActionContainer>
            <BrandButton title="กลับสู่หน้าหลัก" onPress={handleRestartExam} />
          </ResultActionContainer>
        </ResultContainer>
      </ResultScrollView>
    </ResultWrapper>
  )
}

const ResultWrapper = styled.View`
  flex: 1;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  background: ${Colors.brand2};
`
const ResultBackground = styled.ImageBackground`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: ${Colors.brand2};
`
const ResultScrollView = styled.ScrollView`
  height: 100%;
`
const Logo = styled.Image`
  width: 250px;
  height: 27px;
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 2;
`
const ResultContainer = styled.View`
  z-index: 3;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
`
const ResultScoreContainer = styled.View`
  width: 100%;
  padding: 20px;
  flex-direction: column;
  margin-top: 40px;
`
const ResultScoreLeftCol = styled.View`
  padding: 20px;
  align-items: center;
  justify-content: center;
  min-height: 150px;
`
const ResultScoreRightCol = styled.View`
  padding: 20px;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  padding: 60px 60px 70px;
`
const RawScoreLabel = styled.Text`
  font-family: ${Fonts.brandBold};
  color: ${Colors.white};
  font-size: 18px;
  text-align: center;
`
const RawScore = styled.Text`
  font-family: ${Fonts.brand2};
  color: ${Colors.white};
  font-size: 22px;
  text-align: center;
`
const RawScoreBold = styled.Text`
  font-family: ${Fonts.brand2};
  color: ${Colors.white};
  font-size: 32px;
`
const BandScoreLabel = styled.Text`
  font-family: ${Fonts.brandBold};
  color: ${Colors.white};
  font-size: 22px;
  text-align: center;
  margin-top: 15px;
  margin-bottom: 10px;
`
const BandScoreContainer = styled.View`
  background: ${Colors.brand};
  width: 128px;
  height: 128px;
  border-radius: 64px;
  align-items: center;
  justify-content: center;
`
const BandScore = styled.Text`
  font-family: ${Fonts.brand2};
  color: ${Colors.white};
  font-size: 100px;
  line-height: 128px;
`
const ResultSummaryContent = styled.Text`
  font-family: ${Fonts.brand2};
  color: ${Colors.white};
  text-align: center;
  line-height: 38px;
  font-size: 24px;
`
const ResultSummaryGraphicLine1 = styled.View`
  position: absolute;
  left: 40px;
  bottom: 0px;
  height: 150px;
  width: 1px;
  background: ${Colors.white};
  opacity: 0.6;
`
const ResultSummaryGraphicLine2 = styled.View`
  position: absolute;
  left: 0px;
  bottom: 40px;
  height: 1px;
  width: 300px;
  background: ${Colors.white};
  opacity: 0.6;
`

const ResultSummaryGraphicLine3 = styled.View`
  position: absolute;
  right: 40px;
  top: 0px;
  height: 80px;
  width: 1px;
  background: ${Colors.white};
  opacity: 0.6;
`
const ResultSummaryGraphicLine4 = styled.View`
  position: absolute;
  right: 0;
  top: 40px;
  height: 1px;
  width: 80px;
  background: ${Colors.white};
  opacity: 0.6;
`

const ResultContentContainer = styled.View`
  background: ${Colors.white};
  width: 768px;
  padding: 20px;
  flex-direction: row;
  margin-bottom: 20px;
`
const ResultContent = styled.Text`
  padding: 15px;
  flex: 1;
  font-size: 16px;
  line-height: 22px;
`
const ResultActionContainer = styled.View`
  align-items: center;
  padding-bottom: 100px;
`

export default ResultScreen;