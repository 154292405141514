import React, { useState, useContext } from 'react';
import { useNavigation } from '@react-navigation/native';
import styled from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons'; 
import Colors from '../../themes/Colors';
import Fonts from '../../themes/Fonts';
import BrandButton from '../Commons/BrandButton';
import GhostButton from '../Commons/GhostButton';

import { AuthContext } from '../../contexts/AuthContext'
import { ExamContext } from '../../contexts/ExamContext'
import { maskNationalId } from '../../utils/Helpers'

function AccountPanel({ handleBack }) {
  const navigation = useNavigation()
  const { client, subject, codeState } = useContext(AuthContext)
  const { startExam } = useContext(ExamContext)
  const [isStartExamLoading, setIsStartExamLoading] = useState(false)

  const onSubmit = async () => {
    try {
      setIsStartExamLoading(true)
      await startExam(subject, codeState)
      setIsStartExamLoading(false)
      navigation.navigate("Exam")
    } catch (error) {
      console.warn(error)
    }
  }

  const handleEditAccount = () => {
    navigation.navigate("AddEditAccount")
  }

  return (
    <CodeWrapper>
      <BackButton title="ย้อนกลับ" onPress={handleBack} leftElement={() => <MaterialIcons name="chevron-left" size={24} color={Colors.slate600} style={{marginRight: 5}} />} />
      <Title>รายละเอียดผู้สอบ</Title>
      <InfoGroup>
        <Label>ชื่อ-นามสกุล</Label>
        <Info>{ subject?.first_name ? subject.first_name : '-' } { subject?.last_name }</Info>
      </InfoGroup>
      <InfoGroup>
        <Label>ชื่อโรงเรียน</Label>
        <Info>{ client?.name ? client.name : "-" }</Info>
      </InfoGroup>
      <InfoGroup>
        <Label>ชั้น/ห้อง</Label>
        <Info>{ subject?.grade?.name ? subject.grade.name : "-" }</Info>
      </InfoGroup>
      <InfoGroup>
        <Label>เพศ</Label>
        <Info>{ subject?.gender?.name ? subject.gender.name : "-" }</Info>
      </InfoGroup>
      <InfoGroup>
        <Label>เลขบัตรประชาชน</Label>
        <Info> {subject?.national_id ? maskNationalId(subject.national_id) : "-" }</Info>
      </InfoGroup>
      <ActionWrapper>
        <BrandButton
          title="เริ่มทำข้อสอบ"
          onPress={onSubmit}
          disabled={isStartExamLoading}
          loading={isStartExamLoading}
        />
        <TextButton onPress={handleEditAccount}>
          <TextButtonLabel>ข้อมูลไม่ถูกต้อง? แก้ไขข้อมูล > </TextButtonLabel>
        </TextButton>
      </ActionWrapper>
    </CodeWrapper>
  );
}

const CodeWrapper = styled.View`
  padding: 20px;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const BackButton = styled(GhostButton)`
  position: absolute;
  left: 20px;
  top: 20px;
`
const Title = styled.Text`
  color: ${Colors.brand2};
  font-family: ${Fonts.brandBold};
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;
  margin-top: ${({theme}) => theme.screenSize.md ? '40px' : '0' };
`
const InfoGroup = styled.View`
  flex-direction: row;
  width: 100%;
  border-bottom-width: 1px;
  border-bottom-color: ${Colors.slate100};
  /* max-width: 320px; */
`
const Label = styled.Text`
  flex: 2;
  font-family: ${Fonts.brand};
  padding: 10px 0;
  text-align: right;
  color: ${Colors.brand2};
  font-size: 12px;
`
const Info = styled.Text`
  flex: 4;
  font-family: ${Fonts.read};
  padding: 7px 10px;
  font-size: 13px;
`
const ActionWrapper = styled.View`
  margin-top: 30px;
  max-width: 300px;
`
const TextButton = styled.TouchableOpacity`
  margin-top: 20px;
`
const TextButtonLabel = styled.Text`
  font-family: ${Fonts.brandBold};
  text-align: center;
`

export default AccountPanel;