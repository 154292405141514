import React from 'react'
import styled, { useTheme } from 'styled-components/native'
import Images from '../themes/Images'
import ChapterProgress from './ChapterProgress'

function BottomBar({ children, activeChapter }) {
  const theme = useTheme()
  const BOTTOM_BAR_HEIGHT = theme.screenSize.md ? 100 : 80

  const BottomBarBg = styled.ImageBackground`
    position: absolute;
    z-index: 10;
    left: 0;
    bottom: 0;
    width: 100%;
    height: ${BOTTOM_BAR_HEIGHT}px;
    justify-content: center;
    align-items: center;
  `

  const Progress = styled.View`
    height: ${ ({theme}) => theme.screenSize.md ? `20px` : `${BOTTOM_BAR_HEIGHT}px` };
    position: absolute;
    left: 0;
    bottom: ${({theme})=> theme.screenSize.md ? `10px` : 0 };
    justify-content: center;
    padding: ${({theme})=> theme.screenSize.md ? `0` : `20px` };
  `
  const Graphic = styled.ImageBackground`
    width: 200px;
    height: ${BOTTOM_BAR_HEIGHT + 20}px;
    position: absolute;
    right: 0;
    bottom: 0;
  `
  const BottomBarContent = styled.View`
    width: 100%;
    height: ${BOTTOM_BAR_HEIGHT}px;
    position: absolute;
    z-index: 20;
    left: 0;
    bottom: 0;
    justify-content: center;
    align-items: center;
  `

  return (
    <BottomBarBg source={Images.bgBar} resizeMode="cover">
      <Progress>
        <ChapterProgress activeChapter={activeChapter} />
      </Progress>
      <BottomBarContent>{children}</BottomBarContent>
      <Graphic source={Images.graphicBar} resizeMode="contain" />
    </BottomBarBg>
  )
}

export default BottomBar
