import AsyncStorage from '@react-native-async-storage/async-storage'
import { v4 as uuidv4 } from 'uuid';
import sizeof from 'object-sizeof'
import { bytesToSize } from '../utils/Helpers'

import { ALL_CODE_PACKAGES, CODE_PACKAGE_PREFIX, LIST_DATA, ATTEMPT_DATA, SUBJECT_DATA, LOGIN_CODE, SURVEY_MAP_LIST } from '../utils/Constant'

const get = async (key) => {
  try {
    const stringData = await AsyncStorage.getItem(key)
    return stringData != null ? JSON.parse(stringData) : {}
  } catch (error) {
    console.error(error)
    Promise.reject(error)
  }
}
const set = async (key, data) => {
  try {
    // console.warn('Size:',key, bytesToSize(sizeof(data)))
    const stringData = JSON.stringify(data)
    await AsyncStorage.setItem(key, stringData)
    return data
  } catch (error) {
    Promise.reject(error)
  }
}

const remove = async (key) => {
  try {
    await AsyncStorage.removeItem(key)
    return true
  } catch (error) {
    console.error(error)
    Promise.reject(error)
    return false;
  }
}

const addCodePackage = async (code, data) => {
  try {
    const allCodePackages = await get(ALL_CODE_PACKAGES)
    const codeKey = `${CODE_PACKAGE_PREFIX}${code}`
    allCodePackages[code] = {
      code,
      code_key: codeKey,
      create_at: data?.create_at,
      client_name: data?.client?.name,
      graph_name: data?.graph?.name,
      count_item: data?.items?.length,
      count_test: data?.tests?.length,
      count_subject: data?.subjects?.length,
      count_image: data?.images?.length,
    }
    await set(codeKey, data)
    await set(ALL_CODE_PACKAGES, allCodePackages)
  } catch (error) {
    console.error(error)
  }
}

const removeCodePackage = async (code) => {
  const allCodePackages = await get(ALL_CODE_PACKAGES)
  if (allCodePackages && allCodePackages[code]) {
    delete allCodePackages[code]
    await set(ALL_CODE_PACKAGES, allCodePackages)
  }
}

const removeAllCodePackage = async () => {
  try {
    const allCodePackages = await get(ALL_CODE_PACKAGES)
    if (allCodePackages) {
      Object.keys(allCodePackages).map(async code => {
        const codeKey = allCodePackages[code]?.code_key
        await remove(codeKey)
      })
      await set(ALL_CODE_PACKAGES, {})
    }
  } catch (error) {
    console.error(error)
  }
}

const getPackageByCode = async (code) => {
  try {
    const codeKey = `${CODE_PACKAGE_PREFIX}${code}`
    const codeData = await get(codeKey)
    return codeData ? codeData : null
  } catch (error) {
    console.error(error)
    return null
  }
}

const getPackageDataByKey = async (code, key) => {
  // Avalabel key: code | client | graph | tests | items | subjects 
  try {
    const codeData = await getPackageByCode(code)
    return codeData && codeData[key] ? codeData[key] : null
  } catch (error) {
    console.error(error)
    return null
  }
}

const getListDataByType = async (type) => {
  const listData = await get(LIST_DATA)
  if (listData && listData[type]) {
    return listData[type]
  }
  return null
}
const saveSubject = async (id = null, data) => {
  let newData = data
  const subjectId = id ? id : uuidv4()
  try {
    const subjectData = await get(SUBJECT_DATA)
    const listData = await get(LIST_DATA)
    const code = await DB.get(LOGIN_CODE)
    const codePackage = await getPackageByCode(code)
    
    // Get Gender data
    const gender_id = data.gender_id
    const gender = listData['gender'].find(item => item.id == gender_id)
    if (gender) {
      newData.gender = gender
    }
    const grade_id = data.grade_id
    const grade = listData['grade'].find(item => item.id == grade_id)
    if (grade) {
      newData.grade = grade
    }

    // Get survey data
    if (data && data.survey1_id) {
      Object.keys(SURVEY_MAP_LIST).forEach(surveyKey => {
        if (data[`${surveyKey}_id`]) {
          const listId = data[`${surveyKey}_id`]
          const listKey = SURVEY_MAP_LIST[surveyKey]
          const listItem = listData[listKey].find(item => item.id == listId)
          if (listItem) {
            newData[surveyKey] = listItem
            newData[`${surveyKey}_name`] = listItem.name
          }
        }
      })
    }

    // Get Client data
    const client_id = data.client_id
    if (codePackage && codePackage.client && codePackage.client.id == client_id ) {
      newData.client = codePackage.client
    }

    newData.id = subjectId

    // const newAllCodePackages = {...allCodePackages}
    const newCodePackage = {...codePackage}
    if (id == null) {  
      newData.is_new_subject = true
      // Create new subject it have to update in code package
      if (Array.isArray(newCodePackage.subjects) ){
        newCodePackage.subjects.push(newData)
        await set(`${CODE_PACKAGE_PREFIX}${code}`, newCodePackage)
        subjectData[subjectId] = newData
        await set(SUBJECT_DATA, subjectData)
        return newData
      }
    } else {
      // Replace with new subject data in code package
      if (Array.isArray(newCodePackage.subjects) ){
        let newSubject = newData
        newCodePackage.subjects = newCodePackage.subjects.map(subject => {
          if (subject.id == id) {
            newSubject = {
              ...subject,
              ...newData,
            }
            return newSubject
          }
          return subject
        })
        await set(`${CODE_PACKAGE_PREFIX}${code}`, newCodePackage)
        subjectData[subjectId] = newSubject
        await set(SUBJECT_DATA, subjectData)
        return newSubject
      }
    }

    return false
  } catch (error) {
    console.error(error)
  }
}

const getSubjectById = async (id, code = null) => {
  const subjectData = DB.get(SUBJECT_DATA)
  if (subjectData && subjectData[id] ) {
    return subjectData[id]
  }
  if (code) {
    const subjectCodeData = await getPackageDataByKey(code, 'subjects')
    if (subjectCodeData && Array.isArray(subjectCodeData)) {
      const subject = subjectCodeData.find(item => item.id == id)
      return subject
    }
  }
  return null
} 

const saveAttempt = async (code_id, subject_id) => {
  const id = uuidv4()
  try {
    let attemptData = await get(ATTEMPT_DATA)
    if (attemptData && Array.isArray(attemptData)) {
      attemptData = {}
    }
    const code = await DB.get(LOGIN_CODE)
    const codePackage = await getPackageByCode(code)
    const subject = codePackage.subjects?.find(item => item.id == subject_id)
    const attempt = {
      id: id,
      code: codePackage.code,
      subject: subject,
      tested: false,
      created_at: new Date,
    }
    attemptData[id] = attempt
    await set(ATTEMPT_DATA, attemptData)
    return attempt
  } catch (error) {
    console.error(error)
  }

}

const DB = {
  get,
  set,
  remove,
  addCodePackage,
  removeCodePackage,
  removeAllCodePackage,
  getPackageByCode,
  getPackageDataByKey,
  getListDataByType,
  getSubjectById,
  saveSubject,
  saveAttempt,
}

export default DB
