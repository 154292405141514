import React, { useState, useEffect, useContext } from 'react';
import { Alert, Platform } from 'react-native'
import styled from 'styled-components/native';
import { MaterialIcons } from '@expo/vector-icons'; 
import { ExamContext } from '../contexts/ExamContext'
import { AuthContext } from '../contexts/AuthContext'
import useAPI from '../hooks/useAPI'

import Colors from '../themes/Colors';

import GhostButton from '../components/Commons/GhostButton';
import Exam from '../components/Exam';
import BottomBar from '../components/BottomBar';

const TopLeftPanel = styled.View`
  position: absolute;
  top: 20px;
  right: 10px;
  z-index: 10;
`

const ExamWrapper = styled.View`
  flex: 1;
  flex-direction: row;
`
const ExitButton = styled(GhostButton)`
  background: ${Colors.white};
`

function TestQuestionScreen({ navigation, route }) {
  const { api } = useAPI()
  
  const { currentChapterIndex, setCurrentChapterIndex, setQuestions, setTestLoading, ...examContext } = useContext(ExamContext)
  const { loginWithCode } = useContext(AuthContext)
  

  useEffect(() => {
    if (!route || !route.params || !route.params.id ) {
      navigation.navigate('Home', {
        reset: true,
      })
    }

    (async() => {
      setTestLoading(true)
      await loginWithCode('sample123')
      const questionId = route.params.id
      try {
        const resQuestion = await api.get(`/questions/${questionId}`)
        if (resQuestion?.data?.data) {
          
          // const _questions = resItems?.data?.data.map(item => {
          //   return {
          //     ...item?.question,
          //     fvalue: item?.fvalue,
          //     ivalue: item?.ivalue,
          //     options: item?.question_options,
          //     text: item?.question_text,
          //     type: item?.question_type,
          //     uid: item?.question_uid,
          //   }
          // })
          console.log('resQuestion', resQuestion)
          setQuestions([
            resQuestion.data.data
          ])
        }

        
      } catch (error) {
        console.warn(error)
      }
      setTestLoading(false)
    })()

  
  }, [])


  const handleExitExam = () => {
    navigation.navigate('Home', {
      reset: true,
    })
  }

  const renderContent = () => {
    return <Exam />
  }

  return (
    <ExamWrapper>
      <TopLeftPanel>
        <ExitButton 
          onPress={handleExitExam} 
          title="ออกจากการทำข้อสอบ"
          leftElement={()=> <MaterialIcons name="exit-to-app" size={24} color={Colors.slate600} style={{marginRight: 5}} />}
        />
      </TopLeftPanel>
      { renderContent() }
      <BottomBar activeChapter={currentChapterIndex+1}/>
    </ExamWrapper>
  );
}

export default TestQuestionScreen;