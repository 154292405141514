
import { StatusBar, View, Platform } from 'react-native';
import "@expo/match-media";
import { NavigationContainer } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { useFonts, Mitr_300Light, Mitr_500Medium } from '@expo-google-fonts/mitr'
import { Chonburi_400Regular } from '@expo-google-fonts/chonburi'
import { Sarabun_400Regular, Sarabun_700Bold } from '@expo-google-fonts/sarabun'

import htmr from 'htmr';

import { ThemeProvider } from 'styled-components/native'

import { ExamProvider } from './src/contexts/ExamContext';
import { AuthProvider } from './src/contexts/AuthContext';
import { ErrorProvider } from './src/contexts/ErrorContext';
import { OfflineProvider } from './src/contexts/OfflineContext';

import useScreenSize from './src/hooks/useScreenSize';
import HomeScreen from './src/screens/HomeScreen'
import ExamCoverScreen from './src/screens/ExamCoverScreen';
import ExamScreen from './src/screens/ExamScreen'
import ResultScreen from './src/screens/ResultScreen';
import AddEditAccountScreen from './src/screens/AddEditAccountScreen';
import TestExamScreen from './src/screens/TestExamScreen';
import TestQuestionScreen from './src/screens/TestQuestionScreen';
import AdminScreen from './src/screens/AdminScreen';
// import TestWww from './src/components/Admin/TestWww';

const htmlCSS = `
<style>
  .html-wrapper img{
    width: auto !important;
    height: auto !important;
    max-width: 100%;
  }
  .html-wrapper{
    width: 100% !important;
  }
</style>
`

StatusBar.setHidden(true);

export default function App() {
  const screenSize = useScreenSize()
  let [fontsLoaded, error ] = useFonts({
    Mitr_300Light,
    Mitr_500Medium,
    Chonburi_400Regular,
    Sarabun_400Regular,
    Sarabun_700Bold,
  })

  if (!fontsLoaded) {
    return <View />
  }

  const Stack = createNativeStackNavigator();
  const linking = {
    config: {
      screens: {
        TestExam: {
          path: 'test-exam/:id',
          exact: true,
        },
        TestQuestionScreen: {
          path: 'test-question/:id',
          exact: true,
        }
      },
    },
  };

  return (
    <ThemeProvider theme={{ screenSize  }}>
      <OfflineProvider>
        <ErrorProvider>
          <AuthProvider>
              <ExamProvider>
                {/* <TestWww /> */}
                { Platform.OS === 'web' && htmr(htmlCSS)}
                <NavigationContainer linking={linking}>
                  <Stack.Navigator 
                    screenOptions={{
                      headerShown: false,
                    }}>
                    <Stack.Screen name="Home" component={HomeScreen} />
                    <Stack.Screen name="ExamCover" component={ExamCoverScreen} />
                    <Stack.Screen name="AddEditAccount" component={AddEditAccountScreen} />
                    <Stack.Screen name="Exam" component={ExamScreen} />
                    <Stack.Screen name="Result" component={ResultScreen} />
                    <Stack.Screen name="TestExam" component={TestExamScreen} exact={true} />
                    <Stack.Screen name="TestQuestionScreen" component={TestQuestionScreen} exact={true} />
                    <Stack.Screen name="Admin" component={AdminScreen} />

                  </Stack.Navigator>
                </NavigationContainer>
              </ExamProvider>
          </AuthProvider>
        </ErrorProvider>
      </OfflineProvider>
    </ThemeProvider>
  );
}
