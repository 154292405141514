import React, { useContext } from 'react';
import styled from 'styled-components/native';
import Question from './Question';
import { ExamContext } from '../../contexts/ExamContext'

const Loading = styled.ActivityIndicator`
`

function Exam() {
  const { testLoading, questions, currentQuestionIndex, setCurrentQuestionIndex, doResponseAnswer } = useContext(ExamContext)

  if (testLoading == true) return <Loading />
  if (!questions) return null

  const handleNextQuesiton = async () => {
    
    if (questions && currentQuestionIndex + 1 >= questions.length) {
      await doResponseAnswer()
      return
    }
    const questionIndex = currentQuestionIndex+1
    setCurrentQuestionIndex(questionIndex)
  }
  const handlePrevQuestion = () => {
    if (currentQuestionIndex <= 0) {
      return
    }
    const questionIndex = currentQuestionIndex-1
    setCurrentQuestionIndex(questionIndex)
  }

  return (
    <Question 
      currentQuestionIndex={currentQuestionIndex} 
      question={questions[currentQuestionIndex]} 
      handleNextQuesiton={handleNextQuesiton} 
      handlePrevQuestion={handlePrevQuestion} 
    />
  );
}

export default Exam;