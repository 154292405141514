import React from 'react';
import htmr from 'htmr';
import { View } from 'react-native'


function Html({data, width}) {
  if (!data) return null
  return (
    <div className='html-wrapper' style={{
      width: width,
    }}>
      {htmr(data)} 
    </div>
  );
}

export default Html;