import React, { useState } from "react";

const OfflineContext = React.createContext();
const { Provider } = OfflineContext;

const OfflineProvider = ({ children }) => {
  const [isOfflineMode, setIsOfflineMode] = useState(false)

 return (
   <Provider
     value={{
      isOfflineMode,
      setIsOfflineMode,
    }}
   >
    {children}
   </Provider>
 );
};

export { OfflineContext, OfflineProvider };